<template>
  <svg
    width="1154"
    height="1154"
    viewBox="0 0 1154 1154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="577" cy="577" r="577" fill="#222222" />
    <path
      d="M349.941 836C319.549 836 296.412 828.315 280.529 812.945C264.843 797.576 257 774.813 257 744.657V408.176C257 378.604 264.549 356.23 279.647 341.055C294.745 325.685 315.627 318 342.294 318H420.824C435.725 318 447.588 319.556 456.412 322.669C465.431 325.782 474.941 331.327 484.941 339.304L500.529 351.852C508.569 358.273 516.118 362.747 523.176 365.277C530.235 367.806 538.765 369.07 548.765 369.07H804.059C834.451 369.07 857.49 376.853 873.176 392.417C889.059 407.981 897 430.744 897 460.705V744.657C897 774.618 889.451 797.284 874.353 812.654C859.255 828.218 837.98 836 810.529 836H349.941ZM354.353 777.926H799.647C812.196 777.926 821.804 774.715 828.471 768.295C835.137 761.875 838.471 752.244 838.471 739.404V466.25C838.471 453.41 835.137 443.779 828.471 437.359C821.804 430.744 812.196 427.437 799.647 427.437H533.176C518.275 427.437 506.216 425.88 497 422.767C487.98 419.654 478.471 414.207 468.471 406.425L453.176 393.876C445.137 387.261 437.588 382.689 430.529 380.16C423.667 377.631 415.235 376.366 405.235 376.366H354.059C341.902 376.366 332.49 379.576 325.824 385.997C319.353 392.222 316.118 401.464 316.118 413.721V739.112C316.118 752.147 319.353 761.875 325.824 768.295C332.49 774.715 342 777.926 354.353 777.926ZM294.059 532.788V482.593H860.824V532.788H294.059Z"
      fill="#E6E6E6"
    />
  </svg>
</template>
