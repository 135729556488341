import { defineStore } from "pinia";
import { BASE_URL, fetchWrapper } from "@/helpers";

export const useOffice365ResourceStore = defineStore({
  id: "office365Resource",

  actions: {
    async addOffice365ResourceInfo(office365ResourceBody) {

      // Create a new FormData object
      try {
        const response = await fetch(
          `${BASE_URL}/office365Resource/addOffice365ResourceInfo`,
          {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              // Add any required headers here
            },
            body: JSON.stringify(office365ResourceBody),
          }
        );

        if (response.ok) {
          return await response.json();
        } else {
          const errorData = await response.json();
          throw new Error(`Error Creating office365 Resource Info: ${JSON.stringify(errorData)}`);
        }
      } catch (error) {
        console.error("Error Creating office365 Resource Info:", error);
        throw error;
      }
    },

    async getOffice365ResourceInfo(businessId: string) {
      return await fetchWrapper.get(`${BASE_URL}/office365Resource/getOffice365ResourceInfo/${businessId}`);
    },

    async deleteOffice365ResourceInfo(businessId: string) {
      await fetchWrapper.delete(`${BASE_URL}/office365Resource/deleteOffice365ResourceInfo/${businessId}`);
    },
  },
});