<template>
  <section :class="{ 'no-styling': !hasStyling }">
    <h1>{{ title }}</h1>

    <ul
      class="container"
      :class="{ 'no-styling': !hasStyling, 'max-width': maxWidth }"
    >
      <slot />
    </ul>
    <div
      v-if="maxCardCount && cardCount && cardCount > maxCardCount"
      class="button-container"
    >
      <router-link :to="showAllLink">Show all</router-link>
    </div>
  </section>
</template>

<script lang="ts">
export default {
  props: {
    title: String,
    maxCardCount: Number,
    cardCount: {
      type: Number,
      default: 0,
    },
    hasStyling: {
      type: Boolean,
      default: true,
    },
    showAllLink: {
      type: String,
    },
    maxWidth: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/shared/components/list/container.css";
section {
  background-color: var(--color-white);
  border-radius: 10px;

  &:hover {
    box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.08);
    transition: 0.2s;
  }

  &.no-styling {
    background-color: transparent;
  }

  &.no-styling:hover {
    box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0);
  }

  h1 {
    padding-left: 10px;
  }

  .container {
    border-radius: 10px;
    padding: 5px;
    transition: 0.2s;

    &.no-styling {
      padding: 0;
    }
  }

  .container.form {
    display: flex;
  }

  .button-container {
    display: flex;
    justify-content: center;

    a {
      text-decoration: none;
      color: var(--color-black);
      font-weight: 600;
      margin-bottom: 10px;
      margin-top: -5px;
      transition: 0.2s;
      line-height: initial;
      padding: 10px 15px;
      border-radius: 6px;

      &:hover {
        text-decoration: none;
        background-color: var(--color-hover);
        transition: 0.2s;
      }
    }
  }
}

ul.max-width {
  max-width: 100%;
}
</style>
