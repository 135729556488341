<template>
  <svg
    width="1154"
    height="1154"
    viewBox="0 0 1154 1154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="577" cy="577" r="577" fill="#222222" />
    <path
      d="M421.239 893.537C389.877 893.537 365.996 885.333 349.598 868.925C333.199 852.517 325 828.417 325 796.626V358.526C325 326.53 333.199 302.328 349.598 285.92C365.996 269.307 389.877 261 421.239 261H562.37C581.433 261 597.114 263.154 609.413 267.461C621.712 271.563 634.216 280.075 646.925 292.996L796.357 444.67C805.171 453.694 811.833 462.206 816.343 470.205C820.852 478.204 823.927 487.126 825.567 496.971C827.207 506.816 828.027 518.712 828.027 532.659V796.626C828.027 828.417 819.827 852.517 803.429 868.925C787.235 885.333 763.355 893.537 731.787 893.537H421.239ZM427.081 832.006H725.638C739.372 832.006 749.621 828.52 756.385 821.546C763.15 814.778 766.532 804.83 766.532 791.704V543.119H613.718C591.989 543.119 575.693 537.786 564.829 527.121C554.17 516.251 548.841 499.842 548.841 477.896V322.839H427.389C413.655 322.839 403.406 326.325 396.641 333.299C390.082 340.272 386.802 350.22 386.802 363.141V791.704C386.802 804.83 390.082 814.778 396.641 821.546C403.406 828.52 413.552 832.006 427.081 832.006ZM619.252 489.895H759.153L601.726 330.222V472.359C601.726 478.307 603.059 482.716 605.723 485.588C608.593 488.459 613.103 489.895 619.252 489.895Z"
      fill="#E6E6E6"
    />
    <path
      d="M471.04 610.774V754.11"
      stroke="#E6E6E6"
      stroke-width="36.9607"
      stroke-linecap="round"
    />
    <path
      d="M471.04 682.893H681.987"
      stroke="#E6E6E6"
      stroke-width="36.9607"
      stroke-linecap="round"
    />
    <path
      d="M471.04 610.774H681.987"
      stroke="#E6E6E6"
      stroke-width="36.9607"
      stroke-linecap="round"
    />
    <path
      d="M471.04 754.11H681.987"
      stroke="#E6E6E6"
      stroke-width="36.9607"
      stroke-linecap="round"
    />
    <path
      d="M681.986 610.774V754.11"
      stroke="#E6E6E6"
      stroke-width="36.9607"
      stroke-linecap="round"
    />
    <path
      d="M576.513 610.774L576.513 752.307"
      stroke="#E6E6E6"
      stroke-width="36.9607"
      stroke-linecap="round"
    />
  </svg>
</template>
