import { defineStore } from "pinia";
import { useAccountStore } from "./index";
import type { User, AuthState } from "./types";

import { getActivePinia } from "pinia";

import Cookies from "universal-cookie";

import {
  fetchWrapper,
  BASE_URL,
  isCurrentRouteAPublicRoute,
  setAccessTokenCookie,
  setRefreshTokenCookie,
} from "@/helpers";
import { router } from "@/router";

const cookies = new Cookies();

export const useAuthStore = defineStore({
  id: "auth",
  state: (): AuthState => ({
    accountId: String(localStorage.getItem("accountId") || "null"),
    accessToken: cookies.get("token"),
    refreshToken: cookies.get("refresh_token"),
    returnUrl: localStorage.getItem("returnUrl") || "/account",
    formData: {
      fname: "",
      lname: "",
      email: "",
      password: "",
    },
    reverseProgressbar: false,
    isLoginThroughSso: false,
  }),
  actions: {
    async login(email: string, password: string): Promise<void> {
      const user: User = await fetchWrapper.post(`${BASE_URL}/auth/login`, {
        email,
        password,
      });

      const accessTokenValue = user.tokens.access.token;
      const refreshTokenValue = user.tokens.refresh.token;

      this.accountId = user.user.accountId;
      this.accessToken = accessTokenValue;
      this.refreshToken = refreshTokenValue;

      localStorage.setItem("accountId", String(user.user.accountId));
      await setAccessTokenCookie(accessTokenValue);
      await setRefreshTokenCookie(refreshTokenValue);

      if (user.user.belongToBusinessObjId) {
        localStorage.setItem("isBusinessUser", "true");
        localStorage.setItem("businessID", user.user.belongToBusinessObjId);
      }

      if (user.user.belongToBusinessObjId && user.user.role == "admin") {
        localStorage.setItem("businessID", user.user.belongToBusinessObjId);
      }

      const accountStore = useAccountStore();
      accountStore.setUser(user.user);

      if (localStorage.getItem("cardId")) {
        const cardId = localStorage.getItem("cardId");

        router.push(`/c/${cardId}`);

        localStorage.removeItem("returnUrl");
        localStorage.removeItem("cardId");

        return;
      }

      if (user.user.isBusinessUser) {
        localStorage.setItem("isLogin", true);
        router.push("/business/dashboard");

        const isLoginFromMainDomain =
          window.location.host.split(".").length < 3;

        // Workaround for reloading business users (exclude tracking in index.html)
        if (isLoginFromMainDomain) {
          setTimeout(() => {
            router.go(0);
          }, 0);
        }
      } else {
        router.push(this.returnUrl || "/account");
      }

      localStorage.removeItem("returnUrl");
    },
    async ssoLogin(email: string) {
      const user: User = await fetchWrapper.post(`${BASE_URL}/auth/ssoLogin`, {
        email,
      });
      if (user) {
        const accessTokenValue = user.tokens.access.token;
        const refreshTokenValue = user.tokens.refresh.token;

        this.accountId = user.user.accountId;
        this.accessToken = accessTokenValue;
        this.refreshToken = refreshTokenValue;

        localStorage.setItem("accountId", String(user.user.accountId));
        await setAccessTokenCookie(accessTokenValue);
        await setRefreshTokenCookie(refreshTokenValue);

        if (user.user.belongToBusinessObjId) {
          localStorage.setItem("isBusinessUser", "true");
        }

        const accountStore = useAccountStore();
        accountStore.setUser(user.user);

        if (localStorage.getItem("cardId")) {
          const cardId = localStorage.getItem("cardId");

          router.push(`/c/${cardId}`);

          localStorage.removeItem("returnUrl");
          localStorage.removeItem("cardId");
          return user;
        }

        this.isLoginThroughSso = true;
        if (user.user.isBusinessUser) {
          try {
            localStorage.setItem("isLoginThroughSso", "true");
            router.push("/business/dashboard");
          } catch (error) {
            console.log("error");
          }

          const isLoginFromMainDomain =
            window.location.host.split(".").length < 3;

          // Workaround for reloading business users (exclude tracking in index.html)
          if (isLoginFromMainDomain) {
            setTimeout(() => {
              router.go(0);
            }, 0);
          }
        } else {
          router.push(this.returnUrl || "/account");
        }
        return user;
      } else {
        return;
      }
      // localStorage.removeItem("returnUrl");
    },
    async setFormData(formData: {
      fname: string;
      lname: string;
      email: string;
      password: string;
    }) {
      this.formData = formData;
    },
    async getFormData() {
      return this.formData;
    },
    async signup(
      fname: string,
      lname: string,
      email: string,
      password: string
    ) {
      const payload = {
        fname,
        lname,
        email,
        password,
      };

      const user: User = await fetchWrapper.post(
        `${BASE_URL}/auth/register`,
        payload
      );

      const accessTokenValue = user.tokens.access.token;
      const userEmail = user.user.email;

      setAccessTokenCookie(accessTokenValue);
      localStorage.setItem("registeredEmail", JSON.stringify(userEmail));

      router.push("/login");

      //   localStorage.setItem("registeredEmail", JSON.stringify(userEmail));
      //   await this.sendEmailVerification()
      //     .then(() => {
      //       router.push("/verify-email");
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      return user;
    },
    async sendEmailVerification() {
      await fetchWrapper.post(`${BASE_URL}/auth/send-verification-email`);
    },
    async verifyEmail(accessToken: string) {
      return await fetchWrapper
        .get(`${BASE_URL}/auth/verify-email?token=${accessToken}`)
        .then(localStorage.removeItem("registeredEmail"));
    },
    logout(isBusiness?: boolean): void {
      const cardId = localStorage.getItem("cardId");

      const isDev = import.meta.env.DEV;
      const domain = isDev ? "" : ".connectflow.app";

      getActivePinia()._s.forEach((store) => store.$reset());
      localStorage.clear();
      cookies.remove("token", { path: "/", domain: domain });
      cookies.remove("refresh_token", { path: "/", domain: domain });

      if (isBusiness) {
        router.push("/business/login");
      }
      router.push("/login");

      if (cardId) {
        localStorage.setItem("cardId", cardId);
      }
    },
    async resetPassword(accessToken: string, password: string) {
      await fetchWrapper.post(
        `${BASE_URL}/auth/reset-password?token=${accessToken}`,
        {
          password,
        }
      );
    },
    async forgotPassword(email: string) {
      await fetchWrapper.post(`${BASE_URL}/auth/forgot-password`, {
        email,
      });
    },
    async refreshToken() {
      const currentPathname = window.location.pathname;

      // Could be a side effect here, check later
      const isUserOnPublicRoute = isCurrentRouteAPublicRoute(currentPathname);

      if (isUserOnPublicRoute) {
        return;
      }

      const refreshToken = cookies.get("refresh_token");

      if (refreshToken && refreshToken !== "null") {
        const { access: newAccessToken, refresh: newRefreshToken } =
          await fetchWrapper.post(`${BASE_URL}/auth/refresh-tokens`, {
            refreshToken,
          });

        if (newAccessToken) {
          this.access = newAccessToken.token;
          setRefreshTokenCookie(newAccessToken.token);
        }
        if (newRefreshToken) {
          this.refreshToken = newRefreshToken.token;
          setRefreshTokenCookie(newAccessToken.token);
        }
      }
    },
  },
});
