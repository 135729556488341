<template>
  <svg
    width="1154"
    height="1154"
    viewBox="0 0 1154 1154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="577" cy="577" r="577" fill="#E6E6E6" />
    <rect x="492" y="432" width="359" height="359" fill="white" />
    <path
      d="M833 379.125H577L513 313H321C285.8 313 257.32 342.756 257.32 379.125L257 775.875C257 812.244 285.8 842 321 842H833C868.2 842 897 812.244 897 775.875V445.25C897 408.881 868.2 379.125 833 379.125ZM673 478.312C708.2 478.312 737 508.069 737 544.438C737 580.806 708.2 610.562 673 610.562C637.8 610.562 609 580.806 609 544.438C609 508.069 637.8 478.312 673 478.312ZM801 742.812H545V709.75C545 665.777 630.44 643.625 673 643.625C715.56 643.625 801 665.777 801 709.75V742.812Z"
      fill="#222222"
    />
  </svg>
</template>
