import { defineStore } from "pinia";
import { BASE_URL,fetchWrapper } from "@/helpers";

export const useAzureAdStore = defineStore({
  id: "azureAd",
  
  actions: {
    async addAzureADUser(azureADUserData) {
      // Create a new FormData object
      try {
        const response = await fetch(
          `${BASE_URL}/azureAd/addAzureAdUser`,
          {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                // Add any required headers here
              },
              body: JSON.stringify(azureADUserData),  }
        );

        if (response.ok) {
           return await response.json();
        } else {
          const errorData = await response.json();
          throw new Error(`Error adding card type: ${JSON.stringify(errorData)}`);
        }
      } catch (error) {
        console.error("Error adding card type:", error);
        throw error;
      }
    },

    async getAzureADUser(businessId: string) {
      return await fetchWrapper.get(`${BASE_URL}/azureAd/getAzureAdUser/${businessId}`);
    },
    async updateAzureADUser(businessId: string, data) {
      return await fetchWrapper.patch(
        `${BASE_URL}/azureAd/updateAzureAdUser/${businessId}`,
        data
      );
    },
    async deleteAzureADUser(businessId: string) {
      await fetchWrapper.delete(`${BASE_URL}/azureAd/deleteAzureAdUser/${businessId}`);
    },
  },
});