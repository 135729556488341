<script lang="ts">
import IconBars from "@/components/icons/business/IconBars.vue";
import IconPipe from "@/components/icons/business/IconPipe.vue";
import IconChart from "@/components/icons/business/IconChart.vue";
import IconCog from "@/components/icons/business/IconCog.vue";
import IconLink from "@/components/icons/business/IconLink.vue";
import IconUser from "@/components/icons/business/IconUser.vue";
import IconSync from "@/components/icons/business/IconSync.vue";


export default {
  data() {
    return {};
  },
  components: {
    IconBars,
    IconChart,
    IconCog,
    IconLink,
    IconUser,
    IconPipe,
    IconSync
  },
  props: {
    icon: {
      required: true,
      type: String,
    },
  },
};
</script>

<template>
  <div class="sidebar-icon">
    <IconBars v-if="icon === 'bars'" />
    <IconChart v-else-if="icon === 'chart'" />
    <IconCog v-else-if="icon === 'cog'" />
    <IconLink v-else-if="icon === 'link'" />
    <IconUser v-else-if="icon === 'user'" />
    <IconPipe v-else-if="icon === 'pipe'" />
    <IconSync v-else-if="icon === 'sync'" />

    <IconBars v-else />
  </div>
</template>

<style>
.sidebar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
