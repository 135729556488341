<script lang="ts">
import InputWrapper from "@/components/Input/InputWrapper.vue";

import { useBusinessSettingsStore, useAccountStore } from "@/stores";

import { isValidHexColor, isValidOpacity } from "@/helpers";

export default {
  data() {
    return {
      businessSettingsStore: useBusinessSettingsStore(),
      accountStore: useAccountStore(),
      updatedSettings: {},
      businessId: "",
      inputPatterns: {
        fontUrlField: `/^(https?)[^\s'"]*(?<!['"])$/`,
        fontFamilyNameField: `/^[^;]*$/`,
      },
      colors: {
        bgColor: "",
        bgColorOpacity: "",
        textColor: "",
        textColorOpacity: "",
        buttonColor: "",
        buttonColorOpacity: "",
        itemTextColor: "",
        itemTextColorOpacity: "",
        savebuttonBgColor:"",
        savebuttonBgColorOpacity:"",
        savebuttontextColor:"",
        savebuttontextColorOpacity:"",
        connectButtonColor:"",
        connectButtonColorOpacity:"",
        connectButtonText:"",
        connectButtonTextOpacity:"",
      },
    };
  },
  components: {
    InputWrapper,
  },
  async created() {
    this.businessId = this?.accountStore?.user?.belongToBusinessObjId;

    this.setInitialColors();
  },
  methods: {
    async updateSetting(input) {
      let value = input.target.value;
      const property = input.target.id;
      const labelName = input.target.previousSibling.innerHTML;

      if (property === "fontFaceName") {
        value = value.replace(/;/g, "");
      }

      if (property === "fontUrl") {
        value = value
          .replace(/^['"]|['"]$/g, "") // Remove quotes at the beginning and end
          .replace(/\s/g, ""); // Remove spaces
      }

      const updatedSetting = {};

      updatedSetting[property] = value;

      this.businessSettingsStore
        .setBusinessSettings(this.businessId, updatedSetting)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: `${labelName} is updated to ${value}.`,
            life: 5000,
          });
        });
    },
    handleFontUrlInput(value: string) {
      value = value
        .replace(/["']/g, "")
        .replace(/ /g, "")
        .replace(/['" ]/g, "");

      return value;
    },
    async updateColors() {
      this.colors.textColor = this.colors.textColor.trim().toUpperCase();
      this.colors.buttonColor = this.colors.buttonColor.trim().toUpperCase();
      this.colors.itemTextColor = this.colors.itemTextColor
        .trim()
        .toUpperCase();

      const isAllHexValid =
        isValidHexColor(this.colors.bgColor) &&
        isValidHexColor(this.colors.buttonColor) &&
        isValidHexColor(this.colors.textColor) &&
        isValidHexColor(this.colors.itemTextColor);
      const isAllOpacityValid =
        isValidOpacity(this.colors.bgColorOpacity) &&
        isValidOpacity(this.colors.buttonColorOpacity) &&
        isValidOpacity(this.colors.textColorOpacity) &&
        isValidOpacity(this.colors.itemTextColorOpacity);

      if (!isAllHexValid) {
        this.$toast.add({
          severity: "error",
          summary: "Try again",
          detail: "One of the hex code inputs is not valid.",
          life: 5000,
        });
        return;
      }

      if (!isAllOpacityValid) {
        this.$toast.add({
          severity: "error",
          summary: "Try again",
          detail: "One of the opacity inputs is not valid.",
          life: 5000,
        });
        return;
      }

      const updatedColors = {
        bgColor: this.convertHexAndOpacityToColorMixString(
          this.colors.bgColor,
          this.colors.bgColorOpacity
        ),
        buttonColor: this.convertHexAndOpacityToColorMixString(
          this.colors.buttonColor,
          this.colors.buttonColorOpacity
        ),
        textColor: this.convertHexAndOpacityToColorMixString(
          this.colors.textColor,
          this.colors.textColorOpacity
        ),
        itemTextColor: this.convertHexAndOpacityToColorMixString(
          this.colors.itemTextColor,
          this.colors.itemTextColorOpacity
        ),
      };

      this.businessSettingsStore
        .setBusinessSettings(this.businessId, updatedColors)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: "Your color preferences are updated",
            life: 5000,
          });
        });
    },
    async updateConnectColors() {
      this.colors.savebuttonBgColor = this.colors.savebuttonBgColor.trim().toUpperCase();
      this.colors.savebuttontextColor = this.colors.savebuttontextColor.trim().toUpperCase();
      this.colors.connectButtonColor = this.colors.connectButtonColor
        .trim()
        .toUpperCase();
      this.colors.connectButtonText = this.colors.connectButtonText
        .trim()
        .toUpperCase();

      const isAllHexValid =
        isValidHexColor(this.colors.savebuttonBgColor) &&
        isValidHexColor(this.colors.savebuttontextColor) &&
        isValidHexColor(this.colors.connectButtonColor) &&
        isValidHexColor(this.colors.connectButtonText);
      const isAllOpacityValid =
        isValidOpacity(this.colors.savebuttonBgColorOpacity) &&
        isValidOpacity(this.colors.savebuttontextColorOpacity) &&
        isValidOpacity(this.colors.connectButtonColorOpacity) &&
        isValidOpacity(this.colors.connectButtonTextOpacity);

      if (!isAllHexValid) {
        this.$toast.add({
          severity: "error",
          summary: "Try again",
          detail: "One of the hex code inputs is not valid.",
          life: 5000,
        });
        return;
      }

      if (!isAllOpacityValid) {
        this.$toast.add({
          severity: "error",
          summary: "Try again",
          detail: "One of the opacity inputs is not valid.",
          life: 5000,
        });
        return;
      }

      const updatedConnectButtonColors = {
        saveButtonBackgroundColor: this.convertHexAndOpacityToColorMixString(
          this.colors.savebuttonBgColor,
          this.colors.savebuttonBgColorOpacity
        ),
        saveButtonTextColor: this.convertHexAndOpacityToColorMixString(
          this.colors.savebuttontextColor,
          this.colors.savebuttontextColorOpacity
        ),
        connectButtonBackgroundColor: this.convertHexAndOpacityToColorMixString(
          this.colors.connectButtonColor,
          this.colors.connectButtonColorOpacity
        ),
        connectButtonTextColor: this.convertHexAndOpacityToColorMixString(
          this.colors.connectButtonText,
          this.colors.connectButtonTextOpacity
        ),
      };

      this.businessSettingsStore
        .setBusinessSettings(this.businessId, updatedConnectButtonColors)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: "Your color preferences are updated",
            life: 5000,
          });
        });
    },
    async uploadLogo(event) {
      this.uploadBusinessImage(event, "logo");
    },
    async uploadLogoIcon(event) {
      this.uploadBusinessImage(event, "logoIcon");
    },

    async uploadEmailIcon(event) {
      this.uploadBusinessImage(event, "email");
    },
    async uploadPhoneIcon(event) {
      this.uploadBusinessImage(event, "phone");
    },
    async uploadAddressIcon(event) {
      this.uploadBusinessImage(event, "address");
    },
    async uploadMessageIcon(event) {
      this.uploadBusinessImage(event, "message");
    },
    async uploadBackground(event) {
      this.uploadBusinessImage(event, "background");
    },
    async uploadBusinessImage(event, assetType: string) {
      const file = await event.files[0];
      try {
        await this.businessSettingsStore.uploadBusinessAssetType(
          this.businessId,
          assetType,
          file
        );

        this.businessSettingsStore.getBusinessSettings(this.businessId);

        //
      } catch (error) {
        console.log(error);
      }
    },
    removeImage(event) {
      const type = event.target.id;

      const updatedSetting = {};

      updatedSetting[type] = "";
      this.$confirm.require({
        message: "Are you sure you want to delete the image?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.businessSettingsStore
            .setBusinessSettings(this.businessId, updatedSetting)
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Updated",
                detail: "Image is removed",
                life: 5000,
              });
            });
        },
      });
    },
    convertHexAndOpacityToColorMixString(hexColor: string, opacity: string) {
      return `color-mix(in srgb, ${hexColor.toUpperCase()} ${opacity}, white)`;
    },
    retrieveHexFromColorMix(colorMixString: string) {
      if (colorMixString) {
        const match = colorMixString.match(
          /#([0-9a-fA-F]{3}(?:[0-9a-fA-F]{3})?)\b/
        );
        return match ? match[0].toUpperCase() : null;
      } else {
        return null;
      }
    },
    retrieveOpacityFromColorMix(colorMix: string) {
      if (colorMix) {
        const regex = /\d+(?=%)/;
        const match = colorMix.match(regex);
        if (match) {
          return match[0] + "%";
        }
      }
      return null;
    },

    convertColorMixToColorString() {
      const fallbackBlack = "#000000";
      const fallbackWhite = "#FFFFFF";
      const fallbackGrey = "#F7F7F7";
      const fallbackOpacity = "100%";

      this.colors.bgColor =
        this.retrieveHexFromColorMix(
          this.businessSettingsStore.businessSettings.bgColor
        ) ?? fallbackGrey;
      this.colors.bgColorOpacity =
        this.retrieveOpacityFromColorMix(
          this.businessSettingsStore.businessSettings.bgColor
        ) ?? fallbackOpacity;
      this.colors.buttonColor =
        this.retrieveHexFromColorMix(
          this.businessSettingsStore.businessSettings.buttonColor
        ) ?? fallbackWhite;
      this.colors.buttonColorOpacity =
        this.retrieveOpacityFromColorMix(
          this.businessSettingsStore.businessSettings.buttonColor
        ) ?? fallbackOpacity;
      this.colors.textColor =
        this.retrieveHexFromColorMix(
          this.businessSettingsStore.businessSettings.textColor
        ) ?? fallbackBlack;
      this.colors.textColorOpacity =
        this.retrieveOpacityFromColorMix(
          this.businessSettingsStore.businessSettings.textColor
        ) ?? fallbackOpacity;
      this.colors.itemTextColor =
        this.retrieveHexFromColorMix(
          this.businessSettingsStore.businessSettings.itemTextColor
        ) ?? fallbackBlack;
      this.colors.itemTextColorOpacity =
        this.retrieveOpacityFromColorMix(
          this.businessSettingsStore.businessSettings.itemTextColorOpacity
        ) ?? fallbackOpacity;
    },
    async setInitialColors() {
      const businessSettings =
        await this.businessSettingsStore.getBusinessSettings(this.businessId);
        console.log("businessSettings",businessSettings);

      this.colors.bgColor =
        this.retrieveHexFromColorMix(businessSettings.bgColor) || "";
      this.colors.bgColorOpacity =
        this.retrieveOpacityFromColorMix(businessSettings.bgColor) || "";
      this.colors.textColor =
        this.retrieveHexFromColorMix(businessSettings.textColor) || "";
      this.colors.textColorOpacity =
        this.retrieveOpacityFromColorMix(businessSettings.textColor) || "";
      this.colors.buttonColor =
        this.retrieveHexFromColorMix(businessSettings.buttonColor) || "";
      this.colors.buttonColorOpacity =
        this.retrieveOpacityFromColorMix(businessSettings.buttonColor) || "";
      this.colors.itemTextColor =
        this.retrieveHexFromColorMix(businessSettings.itemTextColor) || "";
      this.colors.itemTextColorOpacity =
        this.retrieveOpacityFromColorMix(businessSettings.itemTextColor) || "";
      this.colors.savebuttonBgColor =
        this.retrieveHexFromColorMix(businessSettings.saveButtonBackgroundColor) || "";
      this.colors.savebuttonBgColorOpacity =
        this.retrieveOpacityFromColorMix(businessSettings.saveButtonBackgroundColor) || "";
      this.colors.savebuttontextColor =
        this.retrieveHexFromColorMix(businessSettings.saveButtonTextColor) || "";
      this.colors.savebuttontextColorOpacity =
        this.retrieveOpacityFromColorMix(businessSettings.saveButtonTextColor) || "";
      this.colors.connectButtonColor =
        this.retrieveHexFromColorMix(businessSettings.connectButtonBackgroundColor) || "";
      this.colors.connectButtonColorOpacity =
        this.retrieveOpacityFromColorMix(businessSettings.connectButtonBackgroundColor) || "";
      this.colors.connectButtonText =
        this.retrieveHexFromColorMix(businessSettings.connectButtonTextColor) || "";
      this.colors.connectButtonTextOpacity =
        this.retrieveOpacityFromColorMix(businessSettings.connectButtonTextColor) || "";
    },
  },
  watch: {
    "businessSettingsStore.businessSettings": {
      handler: function (newVal) {
        if (newVal) {
          this.convertColorMixToColorString();
        }
      },
      deep: true,
    },
  },
};
</script>

<template>
  <span for="logo">Logo</span>
  <PVFileUpload
    id="logo"
    class="btn btn-primary btn-upload"
    mode="basic"
    accept="image/*"
    :maxFileSize="1000000"
    :auto="true"
    chooseLabel="Upload image"
    @uploader="uploadLogo"
    customUpload
  />

  <img
    id="logo"
    v-if="this.businessSettingsStore.businessSettings.logo"
    width="200"
    :src="this.businessSettingsStore.businessSettings.logo"
    @click="removeImage"
  />
  <div v-else class="preview logo-preview" />

  <span for="logo-icon">Logo icon</span>
  <PVFileUpload
    id="logo-icon"
    class="btn btn-primary btn-upload"
    mode="basic"
    accept="image/*"
    :maxFileSize="1000000"
    :auto="true"
    chooseLabel="Upload
  image"
    @uploader="uploadLogoIcon"
    customUpload
  />
  <img
    id="logoIcon"
    v-if="this.businessSettingsStore.businessSettings.logoIcon"
    width="100"
    :src="this.businessSettingsStore.businessSettings.logoIcon"
    @click="removeImage"
  />
  <div v-else class="preview logo-icon-preview" />

  <span for="email">Email Icon</span>
  <PVFileUpload
    id="email-icon"
    class="btn btn-primary btn-upload"
    mode="basic"
    accept=".svg, .png, .jpg, .jpeg"
    :maxFileSize="1000000"
    :auto="true"
    chooseLabel="Upload image"
    @uploader="uploadEmailIcon"
    customUpload
  />

  <img
    id="email"
    v-if="this.businessSettingsStore.businessSettings.email"
    width="200"
    :src="this.businessSettingsStore.businessSettings.email"
    @click="removeImage"
  />
  <div v-else class="preview custom-icon-preview" />

  <span for="phone">Phone Icon</span>
  <PVFileUpload
    id="phone-icon"
    class="btn btn-primary btn-upload"
    mode="basic"
    accept=".svg, .png, .jpg, .jpeg"
    :maxFileSize="1000000"
    :auto="true"
    chooseLabel="Upload image"
    @uploader="uploadPhoneIcon"
    customUpload
  />

  <img
    id="phone"
    v-if="this.businessSettingsStore.businessSettings.phone"
    width="200"
    :src="this.businessSettingsStore.businessSettings.phone"
    @click="removeImage"
  />
  <div v-else class="preview custom-icon-preview" />


  <span for="address">Address Icon</span>
  <PVFileUpload
    id="address-icon"
    class="btn btn-primary btn-upload"
    mode="basic"
    accept=".svg, .png, .jpg, .jpeg"
    :maxFileSize="1000000"
    :auto="true"
    chooseLabel="Upload image"
    @uploader="uploadAddressIcon"
    customUpload
  />

  <img
    id="address"
    v-if="this.businessSettingsStore.businessSettings.address"
    width="200"
    :src="this.businessSettingsStore.businessSettings.address"
    @click="removeImage"
  />
  <div v-else class="preview custom-icon-preview" />

  <span for="message">Message Icon</span>
  <PVFileUpload
    id="message-icon"
    class="btn btn-primary btn-upload"
    mode="basic"
    accept=".svg, .png, .jpg, .jpeg"
    :maxFileSize="1000000"
    :auto="true"
    chooseLabel="Upload image"
    @uploader="uploadMessageIcon"
    customUpload
  />

  <img
    id="message"
    v-if="this.businessSettingsStore.businessSettings.message"
    width="200"
    :src="this.businessSettingsStore.businessSettings.message"
    @click="removeImage"
  />
  <div v-else class="preview custom-icon-preview" />

  <span for="">Page colors</span>

  <!-- bgColor -->
  <div class="color-pickers-container">
    <div class="color-picker-container">
      <div class="color-picker-button">
        <p>Background</p>
        <div
          class="color-preview"
          :style="{
            backgroundColor: convertHexAndOpacityToColorMixString(
              this.colors.bgColor,
              this.colors.bgColorOpacity
            ),
          }"
        />
      </div>

      <InputWrapper
        labelText="HEX Code"
        labelRef="bgColor"
        v-model="colors.bgColor"
        class="color-picker-input"
        placeholder="#FFFFFF"
        variant="grey"
      />
      <InputWrapper
        labelText="Opacity"
        labelRef="bgColorOpacity"
        v-model="colors.bgColorOpacity"
        class="color-picker-input"
        placeholder="100%"
        variant="grey"
      />
    </div>

    <!-- textColor -->
    <div class="color-picker-container">
      <div class="color-picker-button">
        <p>Text</p>
        <div
          class="color-preview"
          :style="{
            backgroundColor: convertHexAndOpacityToColorMixString(
              this.colors.textColor,
              this.colors.textColorOpacity
            ),
          }"
        />
      </div>

      <InputWrapper
        labelText="HEX Code"
        labelRef="textColor"
        v-model="colors.textColor"
        class="color-picker-input"
        placeholder="#222222"
        variant="grey"
      />
      <InputWrapper
        labelText="Opacity"
        labelRef="textColor"
        v-model="colors.textColorOpacity"
        class="color-picker-input"
        placeholder="100%"
        variant="grey"
      />
    </div>
  </div>

  <span for="">Page item colors</span>

  <!-- buttonColor -->
  <div class="color-pickers-container">
    <div class="color-picker-container">
      <div class="color-picker-button">
        <p>Background</p>
        <div
          class="color-preview"
          :style="{
            backgroundColor: convertHexAndOpacityToColorMixString(
              this.colors.buttonColor,
              this.colors.buttonColorOpacity
            ),
          }"
        />
      </div>
      <InputWrapper
        labelText="HEX Code"
        labelRef="buttonColor"
        v-model="colors.buttonColor"
        class="color-picker-input"
        placeholder="#222222"
        variant="grey"
      />
      <InputWrapper
        labelText="Opacity"
        labelRef="buttonColor"
        v-model="colors.buttonColorOpacity"
        class="color-picker-input"
        placeholder="100%"
        variant="grey"
      />
    </div>
    <!-- textItemColor -->
    <div class="color-picker-container">
      <div class="color-picker-button">
        <p>Text</p>
        <div
          class="color-preview"
          :style="{
            backgroundColor: convertHexAndOpacityToColorMixString(
              this.colors.itemTextColor,
              this.colors.itemTextColorOpacity
            ),
          }"
        />
      </div>
      <InputWrapper
        labelText="HEX Code"
        labelRef="buttonColor"
        v-model="colors.itemTextColor"
        class="color-picker-input"
        placeholder="#222222"
        variant="grey"
      />
      <InputWrapper
        labelText="Opacity"
        labelRef="buttonColor"
        v-model="colors.itemTextColorOpacity"
        class="color-picker-input"
        placeholder="100%"
        variant="grey"
      />
    </div>
  </div>

  <PVButton class="btn btn-primary btn-save btn-settings" @click="updateColors">
    Save colors</PVButton
  >
  
   <!-- saveButtonColor -->
   <span for="">Save Button</span>

   <div class="color-pickers-container">
    <div class="color-picker-container">
      <div class="color-picker-button">
        <p>Background</p>
        <div
          class="color-preview"
          :style="{
            backgroundColor: convertHexAndOpacityToColorMixString(
              this.colors.savebuttonBgColor,
              this.colors.savebuttonBgColorOpacity
            ),
          }"
        />
      </div>

      <InputWrapper
        labelText="HEX Code"
        labelRef="bgColor"
        v-model="colors.savebuttonBgColor"
        class="color-picker-input"
        placeholder="#FFFFFF"
        variant="grey"
      />
      <InputWrapper
        labelText="Opacity"
        labelRef="savebuttonBgColorOpacity"
        v-model="colors.savebuttonBgColorOpacity"
        class="color-picker-input"
        placeholder="100%"
        variant="grey"
      />
    </div>

    <!-- textColor -->
    <div class="color-picker-container">
      <div class="color-picker-button">
        <p>Text</p>
        <div
          class="color-preview"
          :style="{
            backgroundColor: convertHexAndOpacityToColorMixString(
              this.colors.savebuttontextColor,
              this.colors.savebuttontextColorOpacity
            ),
          }"
        />
      </div>

      <InputWrapper
        labelText="HEX Code"
        labelRef="textColor"
        v-model="colors.savebuttontextColor"
        class="color-picker-input"
        placeholder="#222222"
        variant="grey"
      />
      <InputWrapper
        labelText="Opacity"
        labelRef="textColor"
        v-model="colors.savebuttontextColorOpacity"
        class="color-picker-input"
        placeholder="100%"
        variant="grey"
      />
    </div>
  </div>


  <!-- connectbuttonColor -->
  <span for="">Connect Button</span>

  <div class="color-pickers-container">
    <div class="color-picker-container">
      <div class="color-picker-button">
        <p>Background</p>
        <div
          class="color-preview"
          :style="{
            backgroundColor: convertHexAndOpacityToColorMixString(
              this.colors.connectButtonColor,
              this.colors.connectButtonColorOpacity
            ),
          }"
        />
      </div>
      <InputWrapper
        labelText="HEX Code"
        labelRef="buttonColor"
        v-model="colors.connectButtonColor"
        class="color-picker-input"
        placeholder="#222222"
        variant="grey"
      />
      <InputWrapper
        labelText="Opacity"
        labelRef="buttonColor"
        v-model="colors.connectButtonColorOpacity"
        class="color-picker-input"
        placeholder="100%"
        variant="grey"
      />
    </div>
    <!-- textItemColor -->
    <div class="color-picker-container">
      <div class="color-picker-button">
        <p>Text</p>
        <div
          class="color-preview"
          :style="{
            backgroundColor: convertHexAndOpacityToColorMixString(
              this.colors.connectButtonText,
              this.colors.connectButtonTextOpacity
            ),
          }"
        />
      </div>
      <InputWrapper
        labelText="HEX Code"
        labelRef="buttonColor"
        v-model="colors.connectButtonText"
        class="color-picker-input"
        placeholder="#222222"
        variant="grey"
      />
      <InputWrapper
        labelText="Opacity"
        labelRef="buttonColor"
        v-model="colors.connectButtonTextOpacity"
        class="color-picker-input"
        placeholder="100%"
        variant="grey"
      />
    </div>
  </div>

  <PVButton class="btn btn-primary btn-save btn-settings" @click="updateConnectColors">
    Save colors</PVButton
  >


  <span for="background">Background (.svg only)</span>
  <PVFileUpload
    id="background"
    class="btn btn-primary btn-upload"
    mode="basic"
    accept="image/svg+xml"
    :maxFileSize="1000000"
    :auto="true"
    chooseLabel="Upload
  image"
    @uploader="uploadBackground"
    customUpload
  />

  <img
    id="background"
    v-if="this.businessSettingsStore.businessSettings.background"
    width="100"
    :src="this.businessSettingsStore.businessSettings.background"
    @click="removeImage"
  />
  <div v-else class="preview logo-preview" />

  <span>Font</span>

  <InputWrapper
    class="font-input"
    labelText="URL"
    labelRef="fontUrl"
    v-model="this.businessSettingsStore.businessSettings.fontUrl"
    @change="updateSetting"
    placeholder="https://fonts.googleapis.com/css2?"
    variant="grey"
  />
  <InputWrapper
    class="font-input"
    labelText="Family name"
    labelRef="fontFaceName"
    v-model="this.businessSettingsStore.businessSettings.fontFaceName"
    @change="updateSetting"
    placeholder="'Roboto', sans-serif"
    variant="grey"
  />
</template>

<style lang="scss" scoped>
span {
  color: var(--color-text-grey);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
}

span:not(:nth-of-type(1)) {
  margin-top: 18px;
}

.preview {
  background-color: var(--color-background);
  margin-top: 16px;
  border-radius: 10px;
}

.logo-preview {
  height: 76px;
  width: 191px;
}

.logo-icon-preview {
  height: 76px;
  width: 76px;
}

.custom-icon-preview{
  height: 76px;
  width: 76px;
}

.color-pickers-container {
  display: flex;
  gap: 40px;
}

.color-picker-button {
  background-color: transparent;
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 16px;
  border: 1px solid rgb(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 12px;
  width: 150px;

  p {
    color: #222;
    font-size: 14px;
    font-weight: 600;
  }
}

.btn-settings {
  max-width: 150px;
  font-size: 1em;
  margin-bottom: 16px;
}

.btn-save {
  background-color: #222;

  &:hover {
    background-color: #222 !important;
    opacity: 0.9;
  }
}

.btn-remove {
  background-color: var(--color-red);

  &:hover {
    background-color: var(--color-red) !important;
    opacity: 0.9;
  }
}

img {
  margin-top: 8px;
  border: 1px solid var(--color-grey-border);
}

img:hover {
  opacity: 0.7;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
}

.color-preview {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0, 0.1);
}

.font-input {
  max-width: 400px;
}
</style>
