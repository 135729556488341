export const NINJA_API_KEY = import.meta.env.VITE_NINJA_KEY;
export const IS_DEVELOPMENT = import.meta.env.DEV;
export const IS_LOCAL =
  import.meta.env.VITE_IS_LOCAL_API?.toLowerCase() === "true";
export const BASE_URL = IS_LOCAL
  ? import.meta.env.VITE_LOCAL_API_URL
  : import.meta.env.VITE_API_URL;

export const generalEmailProviders = [
  "@gmail.com",
  "@outlook.com",
  "@yahoo.com",
  "@icloud.com",
  "@protonmail.com",
  "@zoho.com",
  "@aol.com",
  "@gmx.com",
  "@mail.com",
  "@yandex.com",
  "@fastmail.com",
  "@tutanota.com",
  "@mail.ru",
  "@hushmail.com",
  "@mailchimp.com",
  "@roundcube.com",
  "@rackspace.com",
  "@163.com",
  "@comcast.net",
  "@verizon.net",
  "@att.net",
  "@hotmail.com",
  "@hotmail.nl",
  "@live.com",
  "@msn.com",
  "@rediffmail.com",
  "@sbcglobal.net",
  "@bellsouth.net",
  "@cox.net",
  "@earthlink.net",
  "@optonline.net",
  "@rocketmail.com",
  "@aol.co.uk",
  "@btinternet.com",
  "@ntlworld.com",
  "@blueyonder.co.uk",
  "@me.com",
  "@mac.com",
  "@inbox.com",
  "@t-online.de",
  "@web.de",
  "@naver.com",
  "@daum.net",
  "@hanmail.net",
  "@nate.com",
  "@hanafos.com",
  "@lycos.com",
  "@sina.com",
  "@qq.com",
  "@126.com",
  "@sohu.com",
  "@yeah.net",
  "@tom.com",
  "@outlook.co.id",
  "@yahoo.co.id",
  "@aol.co.id",
  "@inbox.lv",
  "@inbox.lt",
  "@yandex.ru",
  "@list.ru",
  "@bk.ru",
  "@mail.kz",
  "@abv.bg",
  "@mail.bg",
  "@tiscali.it",
  "@libero.it",
  "@virgilio.it",
  "@gmx.de",
  "@gmx.at",
  "@gmx.ch",
  "@volny.cz",
  "@seznam.cz",
  "@pobox.sk",
  "@pobox.cz",
  "@o2.pl",
  "@wp.pl",
  "@onet.pl",
  "@interia.pl",
  "@rambler.ru",
  "@mail.ua",
  "@bigmir.net",
  "@ukr.net",
  "@i.ua",
  "@inbox.ru",
  "@list.am",
  "@list.ua",
  "@inbox.lv",
  "@inbox.lt",
];

export const availableUserRoles = [
  {
    name: "Editor",
    code: "editor",
  },
  {
    name: "Viewer",
    code: "viewer",
  },
  {
    name: "Administrator",
    code: "admin",
  },
];

export const businessAdminRoutes = [
  {
    title: "Dashboard",
    url: "/business/dashboard",
    icon: "chart",
  },
  {
    title: "Connections",
    url: "/business/connections",
    icon: "link",
  },
  {
    title: "Users",
    url: "/business/users",
    icon: "user",
  },
  {
    title: "Sync",
    url: "/business/users/sync",
    icon: "sync",
  },
  {
    title: "Fields",
    url: "/business/fields",
    icon: "pipe",
  },
  {
    title: "Page",
    url: "/business/page",
    icon: "bars",
  },

  {
    title: "Settings",
    url: "/business/settings",
    icon: "cog",
  },
];

export const businessViewerRoutes = [
  {
    title: "Dashboard",
    url: "/business/dashboard",
    icon: "chart",
  },
  {
    title: "Connections",
    url: "/business/connections",
    icon: "link",
  },
];

export const businessEditorRoutes = [
  {
    title: "Dashboard",
    url: "/business/dashboard",
    icon: "chart",
  },
  {
    title: "{firstname}",
    url: "/business/users",
    icon: "user",
  },
  {
    title: "Connections",
    url: "/business/connections",
    icon: "link",
  },
];

export const ACCOUNT_ID = localStorage.getItem("accountId") ?? undefined;
