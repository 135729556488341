<script lang="ts">
import { defineAsyncComponent } from "vue";
import ArticleListContainer from "@/components/List/ListContainer/ArticleListContainer.vue";
import ArticleListItem from "@/components/List/ListItem/ArticleListItem.vue";
import ArticleListActionItem from "@/components/List/ListItem/ArticleListActionItem.vue";

import InputWrapper from "@/components/Input/InputWrapper.vue";

import type { BusinessUser } from "@/stores/business/types";

import {
  useBusinessUsersStore,
  useAccountStore,
  useBusinessCardsStore,
  useBusinessGoogleUrlStore,
} from "@/stores";

import IconCsv from "@/components/icons/IconCsv.vue";
import IconFolder from "@/components/icons/IconFolder.vue";
import IconGoogleDriveFolder from "@/components/icons/IconGoogleDriveFolder.vue";
import IconGoogleSheet from "@/components/icons/IconGoogleSheet.vue";

import { availableUserRoles } from "@/helpers";

export default {
  data() {
    return {
      goToEditUser: false,
      businessId: "",
      businessUsersStore: useBusinessUsersStore(),
      accountStore: useAccountStore(),
      businessCardsStore: useBusinessCardsStore(),
      businessGoogleUrlStore: useBusinessGoogleUrlStore(),
      isAddUserPopupVisible: false,
      isImportUserPopupVisible: false,
      selectedImageFiles: null, // Store the selected files
      selectedDataFile: null,
      selectedGoogleSheetLink: null,
      selectedImageDriveLink: null,
      searchQuery: "",
      fileError: false,
      fileGogleError: false,
      newUser: {
        fname: "",
        lname: "",
        role: "viewer",
        email: "",
      },
    };
  },
  async created() {
    this.businessId = this?.accountStore?.user?.belongToBusinessObjId ?? "";
    await this.getBusinessUsers();
    const googleUrlData =
      await this.businessGoogleUrlStore.getBusinessGoogleUrl(this.businessId);
    this.selectedImageDriveLink = googleUrlData.googleDriveUrl;
    this.selectedGoogleSheetLink = googleUrlData.googleSheetUrl;
  },
  components: {
    ArticleListContainer,
    ArticleListItem,
    InputWrapper,
    ArticleListActionItem,
    PVDropdown: defineAsyncComponent(() => import("primevue/dropdown")),
    IconCsv,
    IconFolder,
    IconGoogleDriveFolder,
    IconGoogleSheet,
  },
  computed: {
    availableRoles() {
      return availableUserRoles;
    },
    filteredUsers() {
      const filteredArray = this.businessUsersStore.businessUsers.filter(
        (user: BusinessUser) => {
          const fullName = `${user.fname} ${user.lname}`;
          const email = user.email;
          const functionTitle = user.functionUser;
          const searchQueryLower = this.searchQuery.toLowerCase();

          return (
            fullName.toLowerCase().includes(searchQueryLower) ||
            email.toLowerCase().includes(searchQueryLower) ||
            functionTitle?.toLowerCase().includes(searchQueryLower)
          );
        }
      );

      // Reverse the filteredArray
      const reversedArray = filteredArray.slice().reverse();
      return reversedArray;
    },
  },
  methods: {
    async sendVirtualCardMailToAllUsers() {
      this.$confirm.require({
        message:
          "Are you sure you want to send an email with their virtual card to all your users?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.businessCardsStore
            .sendVirtualCardToAllUsers(this.businessId)
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: `Virtual cards are sent to users`,
                life: 5000,
              });
            })
            .catch((error) => {
              this.$toast.add({
                severity: "error",
                summary: "Failed",
                detail: `Something went wrong: ${error}`,
                life: 5000,
              });
            });
        },
      });
    },
    getSubtitle(user: BusinessUser) {
      if (user.functionTitle !== null && user.functionTitle !== undefined) {
        return `${user.functionTitle}`;
        // return `${user.functionUser} • ${user.userID}`;
      } else {
        // return user.userID;
      }
    },
    async addUserToBusiness() {
      if (!this.businessId) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `Something went wrong. Try again`,
          life: 5000,
        });
        return;
      }

      this.newUser.email = this.newUser.email.toLowerCase();

      await this.businessUsersStore
        .addUserToBusiness(this.businessId, this.newUser)
        .then(async (data) => {
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: "Account is created",
            life: 5000,
          });
          this.isAddUserPopupVisible = false;
          this.getBusinessUsers();
          this.resetInputValues();

          await this.createVirtualCardForUser(data.user.userID);

          if (this.goToEditUser) {
            const userId = data.user.userID;

            this.$router.push(`/business/users/user/${userId}`);

            this.goToEditUser = false;
          }
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Something went wrong: ${error}`,
            life: 5000,
          });
        });
    },
    async createVirtualCardForUser(userId: string) {
      try {
        await this.businessCardsStore.createVirtualCardBusiness(userId);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `Something went wrong: ${error}`,
          life: 5000,
        });
      }
    },
    resetInputValues() {
      this.newUser.fname = "";
      this.newUser.lname = "";
      this.newUser.email = "";
    },
    async getBusinessUsers() {
      if (!this.businessId) {
        this.$router.push("/");
        return;
      }

      await this.businessUsersStore.getBusinessUsers(this.businessId);
    },

    //google sheet
    // Handle folder selection and store selected files
    async importGoogleSheetAndDriveUsersDataToBusiness() {
      this.fileError = false; // Hide error when images are selected

      if (!this.selectedGoogleSheetLink) {
        this.fileGogleError = true;
        return;
      }
      this.isImportUserPopupVisible = false;
      const googleLinks = {
        dataLink: this.selectedGoogleSheetLink,
        imageLink: this.selectedImageDriveLink,
      };
      const response = await this.businessUsersStore.importBusinessUsers(
        this.businessId,
        googleLinks
      );
      let errorLength = 0;
      let createdLength = 0;
      let updatedLength = 0;
      let resultArrayLength = response.results.totalUsers;
      for (const result of response.results.results) {
        if (result.status == "error") {
          errorLength++;
        }
        if (result.status == "updated") {
          updatedLength++;
        }
        if (result.status == "created") {
          createdLength++;
        }
      }
      this.$toast.add({
        severity: "success",
        summary: "Data Imported",
        detail: `${createdLength} new users created and ${updatedLength} users are updated successfully from the file of ${resultArrayLength} users.`,
        life: 5000,
      });

      setTimeout(() => {
        location.reload();
        this.selectedDataFile = null;
        this.selectedImageFiles = null;
      }, 10000);
    },

    handleGoogleSheetInput() {
      this.fileError = false; // Hide error when images are selected

      if (!this.selectedGoogleSheetLink) {
        this.fileGogleError = true;
      } else {
        this.fileGogleError = false;
      }
    },

    // folders
    openFolderInput() {
      this.$refs.folderInput.click();
    },

    async onCsvExcelUpload(event) {
      if (event.files.length > 0) {
        this.fileError = false; // Hide error when a file is selected
        const file = await event.files[0];
        if (file && !file.name.startsWith(".")) {
          this.selectedDataFile = file;
        } else {
          this.$toast.add({
            severity: "warn",
            summary: "Data Imported",
            detail: `Please upload valid CSV/Excel file.`,
            life: 5000,
          });
          this.selectedDataFile = null;
        }
      }
    },

    async importFilesAndFolderUserToBusiness() {
      if (!this.selectedDataFile) {
        this.fileError = true;
        return;
      }
      this.isImportUserPopupVisible = false;
      const response =
        await this.businessUsersStore.importFilesAndFoldersBusinessUsers(
          this.businessId,
          this.selectedDataFile,
          this.selectedImageFiles
        );
      let errorLength = 0;
      let createdLength = 0;
      let updatedLength = 0;
      let resultArrayLength = response.results.totalUsers;
      for (const result of response.results.results) {
        if (result.status == "error") {
          errorLength++;
        }
        if (result.status == "updated") {
          updatedLength++;
        }
        if (result.status == "created") {
          createdLength++;
        }
      }
      this.$toast.add({
        severity: "success",
        summary: "Data Imported",
        detail: `${createdLength} new users created and ${updatedLength} users are updated successfully from the file of ${resultArrayLength} users.`,
        life: 5000,
      });

      setTimeout(() => {
        location.reload();
        this.selectedDataFile = null;
        this.selectedImageFiles = null;
      }, 10000);
    },
    async handleImageFolderUpload(event) {
      this.fileGogleError = false;

      if (event.target.files.length > 0) {
        this.fileError = false; // Hide error when images are selected
      }
      const target = event.target as HTMLInputElement;

      if (target && target.files) {
        // Convert FileList to an array and filter out system files
        this.selectedImageFiles = Array.from(target.files).filter(
          (file) => !file.name.startsWith(".")
        );
      }
    },
  },
};
</script>

<template>
  <div class="users-container">
    <PVPopup
      class="popup"
      v-model:visible="isAddUserPopupVisible"
      modal
      dismissableMask
      @submit.prevent="addUserToBusiness"
      :draggable="false"
      header="New user"
    >
      <form id="add-user-popup" ref="addUserForm">
        <InputWrapper
          labelText="First name"
          labelRef="firstName"
          v-model="newUser.fname"
          variant="grey"
          isRequired
        />

        <InputWrapper
          labelText="Last name"
          labelRef="lastName"
          v-model="newUser.lname"
          variant="grey"
          isRequired
        />
        <InputWrapper
          labelText="Email"
          labelRef="email"
          v-model="newUser.email"
          variant="grey"
          isRequired
        />
        <ArticleListActionItem
          title="Role"
          subtitle="Select a role"
          :isAvatar="false"
          icon="pi pi-link"
          :hasIconBackground="true"
        >
          <PVDropdown
            v-model="newUser.role"
            :options="this.availableRoles"
            optionLabel="name"
            optionValue="code"
            placeholder="Select role"
          />
        </ArticleListActionItem>
      </form>
      <template #footer>
        <div class="btn-user-container">
          <PVButton
            class="btn btn-primary"
            label="Add user"
            autofocus
            form="add-user-popup"
            type="submit"
          />
          <PVButton
            class="btn btn-grey"
            label="Add and edit user"
            form="add-user-popup"
            type="submit"
            @click="goToEditUser = true"
            text
          />
        </div>
      </template>
    </PVPopup>

    <PVPopup
      class="popup"
      v-model:visible="isImportUserPopupVisible"
      modal
      dismissableMask
      :draggable="false"
      header="Import Users"
    >
      <!-- CSV Upload Form -->
      <section>
        <h3>Upload</h3>
        <div class="links-container">
          <a href="https://ctfw.io/import-file-manual" target="_blank"
            >Check out the manual</a
          >
          <div class="option-urls">
            Download
            <a href="https://ctfw.io/import-xlsx-template" target="_blank"
              >Excel</a
            >
            or
            <a href="https://ctfw.io/import-csv-template" target="_blank"
              >CSV</a
            >
            template
          </div>
        </div>
        <form id="csv-upload-form" ref="csvUploadForm" class="">
          <label for="csv" class="import-label">
            <div class="icon-container">
              <IconCsv />
            </div>
            Excel or CSV file</label
          >
          <PVFileUpload
            id="csv"
            class="btn btn-secondary"
            mode="basic"
            name="csv"
            accept=".csv, .xlsx, .xls"
            :auto="true"
            chooseLabel="Select Excel or CSV File"
            customUpload
            @uploader="onCsvExcelUpload"
            :multiple="false"
          />
          <div class="file-upload-container">
            <label class="import-label" for="image-folder">
              <div class="icon-container">
                <IconFolder />
              </div>
              Image Folder</label
            >
            <input
              id="image-folder"
              name="image-folder"
              type="file"
              style="display: none"
              ref="folderInput"
              webkitdirectory
              directory
              multiple
              accept="image/*"
              @change="handleImageFolderUpload"
            />
            <PVButton
              type="button"
              class="btn btn-secondary file-upload-btn"
              @click="openFolderInput"
            >
              Select Image Folder
            </PVButton>
          </div>
          <p class="error-message" v-if="this.fileError">Please upload file</p>
        </form>

        <!-- Separate Button for CSV Upload Form -->
        <div class="w-half">
          <PVButton
            class="btn btn-primary"
            id="uploadFilesFolderBtn"
            label="Import"
            @click="importFilesAndFolderUserToBusiness"
          />
        </div>
      </section>

      <hr />

      <!-- Google Sheet Form -->
      <section>
        <h3>Google Drive</h3>
        <div class="links-container">
          <a href="https://ctfw.io/import-drive-manual" target="_blank"
            >Check out the manual</a
          >
          <div class="option-urls">
            Copy
            <a
              href="https://ctfw.io/import-google-sheets-template"
              target="_blank"
              >Google Sheets</a
            >
            template
          </div>
        </div>
        <form id="google-sheet-form" ref="googleSheetForm">
          <label class="import-label" for="googleSheetLink">
            <div class="icon-container">
              <IconGoogleSheet />
            </div>
            Public Google Sheet</label
          >
          <InputWrapper
            labelText="URL"
            labelRef="googleSheetLink"
            v-model="this.selectedGoogleSheetLink"
            @change="handleGoogleSheetInput"
            variant="grey"
          />
          <label class="import-label" for="imageDriveLink">
            <div class="icon-container">
              <IconGoogleDriveFolder />
            </div>
            Public Drive Folder</label
          >
          <InputWrapper
            labelText="URL"
            labelRef="imageDriveLink"
            v-model="this.selectedImageDriveLink"
            variant="grey"
          />
          <p class="error-message" v-if="this.fileGogleError">
            Please add the Google Sheet link
          </p>
        </form>

        <!-- Separate Button for Google Sheet Form -->
        <div class="w-half">
          <PVButton
            class="btn btn-primary"
            label="Import"
            @click="importGoogleSheetAndDriveUsersDataToBusiness"
          />
        </div>
      </section>
    </PVPopup>

    <div class="heading">
      <span class="p-input-icon-left input-icon full-width">
        <i class="pi pi-search" />
        <PVInputText
          class="p-inputtext p-component"
          v-model="this.searchQuery"
          placeholder="Find user"
        />
      </span>

      <div class="virtual-card-container">
        <PVButton
          class="btn btn-secondary add add-user"
          label="Import"
          @click="isImportUserPopupVisible = true"
          icon="pi pi-file-import"
        />
      </div>

      <div class="virtual-card-container" v-if="filteredUsers.length > 1">
        <PVButton
          class="btn btn-secondary send-cards"
          label="Send virtual cards"
          @click="sendVirtualCardMailToAllUsers"
        />
      </div>

      <div>
        <PVButton
          class="btn btn-primary add add-user"
          label="Add user"
          icon="pi pi-plus"
          @click="isAddUserPopupVisible = true"
        />
      </div>
    </div>
    <main>
      <ArticleListContainer
        title="Users"
        avatar="url"
        :max-width="true"
        v-if="filteredUsers.length > 0"
      >
        <ArticleListItem
          v-for="user in filteredUsers"
          :key="user.userID"
          :title="`${user.fname} ${user.lname}`"
          :subtitle="this.getSubtitle(user)"
          :link="`/business/users/user/${user.userID}`"
          :isAvatar="true"
          :imageUrl="user.image"
          icon="pi pi-user"
        />
      </ArticleListContainer>
      <p v-if="filteredUsers.length < 1">
        Can't find a user, try another search term
      </p>
    </main>
  </div>
</template>

<style scoped lang="scss">
h3 {
  color: var(--color-black);
}

button.add-user {
  width: 200px;
}

button.send-cards {
  width: 180px;
}

.users-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  column-gap: 20px;
  animation: move-up-50 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}

.p-input-icon-left {
  position: relative;
  display: flex;
  align-items: center;
}

.p-input-icon-left .pi {
  position: absolute;
  left: 10px;
  font-size: 1rem;
}

.p-input-icon-left input {
  padding-left: 40px;
}

main {
  margin-top: 60px;
  animation: move-up-100 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}

.popup form {
  display: flex;
  flex-direction: column;
}

.btn-user-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.error-message {
  color: red;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0px;
}

.file-upload-container {
  position: relative;
  display: inline-block;
}

.file-upload-btn {
  //   background-color: #222;
  //   border: 1px solid #222;
  //   color: white;
  font-size: 16px;
  cursor: pointer;
  //   display: inline-block;
  //   text-align: center;
  border-radius: 4px;
  font-weight: 600;
  width: 100% !important;
}

#uploadFilesFolderBtn {
  margin-top: 20px;
}

#google-sheet-form {
  margin-top: 20px;
  margin-bottom: 20px;
}

.w-half {
  width: 50%;
}

hr {
  margin: 1.5rem 0;
  border: 1px solid var(--color-grey);
  border-radius: 10px;
}

.input-wrapper.input-inline-label {
  margin-bottom: 0px;
}

.import-label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 12px 0;
  font-weight: 600;
  font-size: 1.1em;
  color: var(--color-black);
}

.icon-container {
  height: 2rem;
  width: 2rem;
  svg {
    height: 100%;
    width: 100%;
  }
}

.links-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2rem;
  top: 0;
  font-size: 0.8em;
  align-items: flex-end;

  a {
    text-decoration: none;
    color: var(--color-black);
    font-weight: 600;
    line-height: 1.25em;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
