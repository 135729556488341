<script>
import { defineAsyncComponent } from "vue";
import InputWrapper from "@/components/Input/InputWrapper.vue";
import ArticleListContainer from "@/components/List/ListContainer/ArticleListContainer.vue";
import ArticleListItem from "@/components/List/ListItem/ArticleListItem.vue";
import ArticleListActionItem from "@/components/List/ListItem/ArticleListActionItem.vue";
import IconSync from "@/components/icons/business/IconSync.vue";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  useAccountStore,
  useBusinessUsersStore,
  useOffice365WebhookToggleStore,
  useOffice365ResourceStore,
  useClientDataStore,
  useAzureAdStore,
} from "@/stores";

import IconUsers from "../../components/icons/IconUsers.vue";
import IconGroup from "../../components/icons/IconGroup.vue";
import IconRefresh from "../../components/icons/IconRefresh.vue";

export default {
  components: {
    PVDropdown: defineAsyncComponent(() => import("primevue/dropdown")),
    IconSync,
    InputWrapper,
    IconGroup,
    IconUsers,
    IconRefresh,
    ArticleListContainer,
    ArticleListItem,
    ArticleListActionItem,
  },
  data() {
    return {
      accountStore: useAccountStore(),
      azureAdStore: useAzureAdStore(),
      businessUsersStore: useBusinessUsersStore(),
      office365ResourceStore: useOffice365ResourceStore(),
      office365WebhookToggleStore: useOffice365WebhookToggleStore(),
      clientDataStore: useClientDataStore(),
      businessId: "",
      azureAdAdminData: null,
      office365ResourceData: null,
      msalInstance: null,
      userInfo: null,
      profileImageUrl: null,
      groupName: null,
      isAuthorizedChecked: false,
      msalInitialized: false,
      organizationUsers: [], // Add this line to store the organization users
      dropdownOptions: [
        { name: "User Group 1", code: "user-group-1" },
        { name: "User Group 2", code: "user-group-2" },
      ],
      selectedOption: null, // Add this line to store the selected dropdown option
      isSyncedChecked: false,
      typingTimeout: null,
      isSyncOffice365UsersPopupVisible: false,
      authenticationStatus: "Unauthorized",
      authoziationPopupCheck: false,
      userRadioSelection: "all-users",
      isDevelopment: import.meta.env.VITE_USER_NODE_ENV=== "DEVELOPMENT",
      isProduction: import.meta.env.VITE_USER_NODE_ENV=== "PRODUCTION",
      selectedGroupName: null,
      envData:import.meta.env,
      office365WebhookToggleStoreData: null,
      clientDataError: "",
      clientId: "951ba740-857d-42e6-a4b6-9513d6dda558",
      tenantId: "1ff3b07c-9769-4b50-81af-3e96622b861e",
      clientData: null,
      accessToken: null
    };
  },
  async created() {
    // Initialize msalInstance
    this.businessId = this.accountStore.user.belongToBusinessObjId;
    const result = await this.clientDataStore.getClientData(this.businessId);
    this.clientData = result;
    this.clientId = result ? result.clientId : null;
    this.tenantId = result ? result.tenantId : null;

    const msalConfig = {
      auth: {
        clientId: `${this.clientId}`,
        authority: `https://login.microsoftonline.com/${this.tenantId}`,
        redirectUri: this.redirectUri,
      },
    };

    this.msalInstance = new PublicClientApplication(msalConfig);
    await this.msalInstance.initialize(); // Ensure MSAL instance is initialized
    this.msalInitialized = true;
    this.azureAdAdminData = await this.azureAdStore.getAzureADUser(
      this.businessId
    );

    this.accessToken = this.azureAdAdminData.accessToken;
    if (this.azureAdAdminData.isAuthorize == true) {
      this.isAuthorizedChecked = true;
    }
    if (this.azureAdAdminData && this.azureAdAdminData.isAuthorize) {
      this.isAuthorizedChecked = true;
      this.authenticationStatus = "Authorized";
      const groupname = await this.fetchGroups(
        this.azureAdAdminData.accessToken
      );
      

      this.dropdownOptions = groupname.map((group) => ({
        name: group.displayName,
        code: group.displayName,
      }));
    }

    this.office365ResourceData =
      await this.office365ResourceStore.getOffice365ResourceInfo(
        this.businessId
      );
    this.selectedOption = this.office365ResourceData
      ? this.office365ResourceData.isGroup
        ? ((this.selectedGroupName = this.office365ResourceData.groupName),
          (this.userRadioSelection = this.office365ResourceData.groupName),
          "group") // Value for `this.selectedOption`
        : this.office365ResourceData.isUser
        ? "all-users" // Set to "all-users" if `isUser` is true
        : this.selectedOption // Keep existing value if neither `isGroup` nor `isUser`
      : this.selectedOption; // Keep existing value if `office365ResourceData` is not set

    this.office365WebhookToggleStoreData =
      await this.office365WebhookToggleStore.getOffice365WebhookToggleInfo(
        this.businessId
      );

    if (this.office365WebhookToggleStoreData) {
      this.isSyncedChecked = this.office365WebhookToggleStoreData.isWebhook;
    }
  },
  computed: {
    authorizationStatus() {
      return this.azureAdAdminData
        ? this.office365ResourceData
          ? !this.office365ResourceData.groupName
            ? "Active | All Users"
            : `Active | ${this.office365ResourceData.groupName}`
          : "Inactive"
        : "Inactive";
    },

    redirectUri() {
      if(this.isProduction){
         return"https://connectflow.app"
      }
      else if(this.isDevelopment){
        // return "http://localhost:5173";
        return "https://frontend-test.connectflow.app";
      }else{
         return "http://localhost:5173";
      }
    },
  },
  methods: {
    async deleteOfffice365Users() {
      this.$confirm.require({
        message:
          "This action will delete all the business users from Microsoft 365.",
        header: "Are you sure?",
        accept: async () => {
          await this.businessUsersStore
            .deleteOffice365UserToBusiness(this.businessId)
            .then((resp) => {
              this.office365WebhookToggleStore.deleteOffice365WebhookToggleInfo(
                this.businessId
              );
              this.office365ResourceStore.deleteOffice365ResourceInfo(
                this.businessId
              );
              this.$toast.add({
                severity: "success",
                summary: "Updated",
                detail: `Office 365 users are deleted successfully.`,
                life: 5000,
              });
              // this.authenticationStatus = "Unauthorized";
              setTimeout(() => {
                location.reload();
              }, 5000);
              // this.azureAdStore.deleteAzureADUser(this.businessId).then(() => {
              //   this.office365ResourceStore.deleteOffice365ResourceInfo(this.businessId)
              //   this.$toast.add({
              //     severity: "success",
              //     summary: "Updated",
              //     detail: `AzureAdUser is deleted successfully.`,
              //     life: 5000,
              //   });
              //   this.authenticationStatus = "Unauthorized";
              //   setTimeout(() => {
              //     location.reload();
              //   }, 5000)
              // })
            });
        },
        reject: () => {
          return;
        },
      });
    },
    closePopup() {
      // Handle the emitted value here, if needed
      this.isSyncOffice365UsersPopupVisible = false;
      this.authoziationPopupCheck = false;
      this.selectedOption = null;
    },
    async openPopup() {
      this.isSyncOffice365UsersPopupVisible = true;
      this.selectedOption = this.office365ResourceData
        ? this.office365ResourceData.isGroup
          ? ((this.selectedGroupName = this.office365ResourceData.groupName),
            (this.userRadioSelection = this.office365ResourceData.groupName),
            "group") // Value for `this.selectedOption`
          : this.office365ResourceData.isUser
          ? "all-users" // Set to "all-users" if `isUser` is true
          : this.selectedOption // Keep existing value if neither `isGroup` nor `isUser`
        : this.selectedOption; // Keep existing value if `office365ResourceData` is not set
    },

    async handleAuthorization() {
      if (!this.clientData) {
        this.$toast.add({
          severity: "info",
          summary: "Missing credentials",
          detail: "Save your credentials before continuing.",
          life: 5000,
        });
        return;
      }

      this.authorize();
      if (this.isAuthorizedChecked == true) {
        this.authorize();
      }
    },

    async authorize() {
      if (!this.msalInitialized) {
        console.error("MSAL instance is not initialized yet.");
        return;
      }

      try {
        this.msalInstance.clearCache();

        const loginResponse = await this.loginUser();

        const refreshToken = this.getRefreshToken();

        const data = this.prepareUserData(loginResponse, refreshToken);
        this.authenticationStatus = "Authorized";
        this.accessToken = loginResponse.accessToken;

        // Store user data in Azure AD
        const result = await this.azureAdStore.addAzureADUser(data);
        if (!result.msg) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "User is authorized",
            life: 5000,
          });
          location.reload();
          this.authenticationStatus = "Authorized";
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: "User is already authorized",
            life: 5000,
          });
        }
      } catch (error) {
        console.error("Login failed:", error);
      }
    },

    async loginUser() {
      return await this.msalInstance.loginPopup({
        scopes: [
          "User.Read",
          "Group.Read.All",
          "Application.ReadWrite.All",
          "Directory.ReadWrite.All",
          "User.ReadBasic.All"
        ],
        prompt: "select_account",
        account: this.msalInstance.getAllAccounts()[0],
        forceRefresh: true,
      });
    },

    getRefreshToken() {
      const sessionData = {};
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key) {
          sessionData[key] = sessionStorage.getItem(key);
        }
      }

      const msalTokenKey = Object.keys(sessionData).find((key) =>
        key.startsWith("msal.token.keys")
      );

      if (msalTokenKey) {
        const tokenData = JSON.parse(sessionData[msalTokenKey]);
        return tokenData.refreshToken && tokenData.refreshToken[0];
      } else {
        console.error("MSAL token key not found in session storage.");
        return null;
      }
    },

    async silentLogin() {
      const ssoRequest = {
        scopes: [
          "User.Read",
          "Group.Read.All",
          "Application.ReadWrite.All",
          "Directory.ReadWrite.All",
          "User.ReadBasic.All"
        ],
        clientId: `${this.clientId}`,
        authority: `https://login.microsoftonline.com/${this.tenantId}`,
        redirectUri: this.redirectUri,
      };

      try {
        // Try silent login first
        const response = await this.msalInstance.ssoSilent(ssoRequest);
        this.accessToken = response.accessToken;
        return response.accessToken;
      } catch (error) {
        // If interaction is required (like MFA), prompt the user with a login popup
        try {
          const resp = await this.msalInstance.loginRedirect({
            scopes: [
              "User.Read",
              "Group.Read.All",
              "Application.ReadWrite.All",
              "Directory.ReadWrite.All",
            ],
          });
          return resp.accessToken; // Return the token after successful login
        } catch (interactiveError) {
          console.error("Interactive login failed:", interactiveError);
          throw interactiveError;
        }
      }
    },

    //proper silent code but with few error
    // async silentLogin() {
    //   console.log("caled")

    //   const ssoRequest = {
    //     scopes: ["User.Read",
    //       "Group.Read.All",
    //       "Application.ReadWrite.All",
    //       "Directory.ReadWrite.All",], // Minimum scope for silent login
    //     clientId: `${this.clientId}`,
    //     authority: `https://login.microsoftonline.com/${this.tenantId}`,
    //     redirectUri: this.redirectUri,

    //   };

    //   try {
    //     const response = await this.msalInstance.ssoSilent(ssoRequest);
    //     console.log("this.msalInstance.ssoSilent(ssoRequest)",response);

    //     return response.accessToken;
    //   } catch (error) {
    //     console.error("Silent login failed:", error);
    //     throw error;
    //   }
    // },
    async refreshAccessToken() {
      const accounts = this.msalInstance.getAllAccounts();

      if (accounts.length === 0) {
        this.silentLogin().then((resp) => {
          return {
            accessToken: resp,
          };
        });
        // throw new Error("No accounts found.");
      }

      const silentRequest = {
        account: accounts[0],
        scopes: [
          "User.Read",
          "Group.Read.All",
          "Application.ReadWrite.All",
          "Directory.ReadWrite.All",
        ],
      };

      try {
        const response = await this.msalInstance.acquireTokenSilent(
          silentRequest
        );
        this.accessToken = response.accessToken;

        return {
          accessToken: response.accessToken,
        };
      } catch (error) {
        console.error("Failed to refresh access token:", error);
        throw error;
      }
    },

    prepareUserData(loginResponse, refreshToken) {
      return {
        homeAccountId: loginResponse.account.homeAccountId,
        environment: loginResponse.account.environment,
        tenantId: loginResponse.account.tenantId,
        userName: loginResponse.account.username,
        localAccountId: loginResponse.account.localAccountId,
        authority: loginResponse.authority,
        accessToken: loginResponse.accessToken,
        refreshToken: refreshToken,
        idToken: loginResponse.idToken,
        expiresOn: loginResponse.expiresOn,
        businessId: this.businessId,
        isAuthorize: true,
      };
    },

    //no need of this as we are using handleLogout
    signOut() {
      if (!this.msalInitialized) {
        console.error("MSAL instance is not initialized yet.");
        return;
      }

      try {
        const account = this.msalInstance.getAllAccounts()[0];
        if (account) {
          this.msalInstance.logoutPopup({
            account: account,
            postLogoutRedirectUri: this.redirectUri,
          });
          // Clear cache (e.g., access tokens, account info)
          this.msalInstance.clearCache();
            console.log("Cache cleared and user logged out.");
        } else {
          console.error("No account found to sign out.");
        }
      } catch (error) {
        console.error("Sign out failed:", error);
      }
    },

    async fetchGroups(accessToken) {
      // const groupsEndpoint = "https://graph.microsoft.com/v1.0/groups";
      const groupsEndpoint = "https://graph.microsoft.com/beta/groups";

      const headers = new Headers();
      headers.append("Authorization", `Bearer ${accessToken}`);

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        let groupsResponse = await fetch(groupsEndpoint, options);
        if (groupsResponse.status === 401) {
          const newTokens = await this.refreshAccessToken();
          headers.set("Authorization", `Bearer ${newTokens.accessToken}`);
          groupsResponse = await fetch(groupsEndpoint, options);
        }
        const groups = await groupsResponse.json();
        return groups.value; // Return the array of groups
      } catch (error) {
        console.error("Failed to fetch groups:", error);
      }
    },
    async getOrganizationUsers(accessToken) {
      // const usersEndpoint = "https://graph.microsoft.com/v1.0/users";
      const usersEndpoint = "https://graph.microsoft.com/beta/users";

      const headers = new Headers();
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        let usersResponse = await fetch(usersEndpoint, options);
        if (usersResponse.status === 401) {
          const newTokens = await this.refreshAccessToken();
          headers.set("Authorization", `Bearer ${newTokens.accessToken}`);
          usersResponse = await fetch(usersEndpoint, options);
        }
        const users = await usersResponse.json();
        return users.value; // Return the array of users
      } catch (error) {
        console.error("Failed to fetch organization users:", error);
      }
    },
    //groupMethods
    //3
    async getGroupIdByName(groupName, accessToken) {
      // const groupsEndpoint = `https://graph.microsoft.com/v1.0/groups?$filter=displayName eq '${groupName}'`;
      const groupsEndpoint = `https://graph.microsoft.com/beta/groups?$filter=displayName eq '${groupName}'`;

      const headers = new Headers();
      headers.append("Authorization", `Bearer ${accessToken}`);

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        let groupResponse = await fetch(groupsEndpoint, options);
        if (groupResponse.status === 401) {
          const newTokens = await this.refreshAccessToken();
          headers.set("Authorization", `Bearer ${newTokens.accessToken}`);
          groupResponse = await fetch(groupsEndpoint, options);
        }
        const groupData = await groupResponse.json();
        if (groupData.value && groupData.value.length > 0) {
          return groupData.value[0].id; // Return the ID of the first matching group
        } else {
          throw new Error(`Group with name '${groupName}' not found.`);
        }
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `${groupName} is not found.`,
          life: 5000,
        });
        this.selectedOption = null;
        this.groupName = null;
      }
    },
    //2
    async getGroupMembers(groupId, accessToken) {
      // const membersEndpoint = `https://graph.microsoft.com/v1.0/groups/${groupId}/members`;
      const membersEndpoint = `https://graph.microsoft.com/beta/groups/${groupId}/members`;

      const headers = new Headers();
      headers.append("Authorization", `Bearer ${accessToken}`);

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        let membersResponse = await fetch(membersEndpoint, options);
        if (membersResponse.status === 401) {
          const newTokens = await this.refreshAccessToken();
          headers.set("Authorization", `Bearer ${newTokens.accessToken}`);
          membersResponse = await fetch(membersEndpoint, options);
        }
        const members = await membersResponse.json();
        return members.value;
      } catch (error) {
        console.error("Failed to fetch group members:", error);
      }
    },
    //1
    async getGroupMembersByName(groupName, accessToken) {
      try {
        const groupId = await this.getGroupIdByName(groupName, accessToken);
        if (groupId) {
          return await this.getGroupMembers(groupId, accessToken);
        }
      } catch (error) {
        console.error("Failed to fetch group members by name:", error);
      }
    },

    async toggleSyncUsers() {
      if (this.groupName) {
        this.organizationUsers = await this.getGroupMembersByName(
          this.groupName,
          this.azureAdAdminData.accessToken
        );
        await this.processOrganizationUsers();
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: "Please enter valid group name;",
          life: 5000,
        });
      }
    },
    async processOrganizationUsers() {
      // Fetch organization users if not already fetched
      const userDataArray = [];

      for (const user of this.organizationUsers) {
        const fname = user.givenName && user.surname
          ? user.givenName
          : user.displayName
            ? user.displayName.split(' ')[0]
            : '';

        const lname = user.givenName && user.surname
          ? user.surname
          : user.displayName
            ? user.displayName.split(' ').slice(1).join(' ')
            : '';

        const userData = {
          fname: fname,
          lname: lname,
          functionUser: user.jobTitle,
          email: user.mail || user.userPrincipalName,
          phoneNumber: user.mobilePhone,
          businessPhone: user.businessPhones[0],
          azureUserID: user.id,
          accessToken: this.accessToken,
          address:
            user.streetAddress || user.postalCode || user.city || user.country
              ? `${user.streetAddress};${user.postalCode};${user.city};${user.country}`
              : null,
        };
        userDataArray.push(userData);
      }
      this.businessUsersStore
        .addUpdateOffice365UserToBusiness(this.businessId, userDataArray)
        .then((response) => {
          const resultArray = response.results;
          let errorLength = 0;
          let existsLength = 0;

          let resultArrayLength = resultArray.length;
          for (const result of resultArray) {
            if (result.status == "error") {
              errorLength++;
            }
            if (result.status == "exists") {
              existsLength++;
            }
          }
          this.$toast.add({
            severity: "success",
            summary: "Synced",
            detail: `${
              resultArrayLength - errorLength - existsLength
            } out of ${resultArrayLength} users synced successfully.`,
            life: 5000,
          });
          this.isSyncedChecked = true;

          const office365WebhookToggleBody = {
            businessId: this.businessId,
            isWebhook: this.isSyncedChecked,
          };

          this.office365WebhookToggleStore.addoffice365WebhookToggleInfo(
            office365WebhookToggleBody
          );

          //   setTimeout(() => {
          //     location.reload();
          //   }, 2_000);

          this.selectedOption = null;
          // this.closePopup();
        });

      this.office365ResourceData =
        await this.office365ResourceStore.getOffice365ResourceInfo(
          this.businessId
        );

      this.azureAdAdminData = await this.azureAdStore.getAzureADUser(
        this.businessId
      );
    },
    handleGroupInput() {
      // Clear the previous timeout if it exists
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout);
      }

      // Set a new timeout to call the fetchGroupMembers method
      this.typingTimeout = setTimeout(() => {
        this.fetchGroupMembers();
      }, 300); // Delay in milliseconds (300 ms here)
    },
    async fetchGroupMembers() {
      try {
        // Call your method to fetch group members
        this.organizationUsers = await this.getGroupMembersByName(
          this.selectedGroupName,
          this.azureAdAdminData.accessToken
        );
        const office365ResourceBody = {
          businessId: this.businessId,
          isGroup: true,
          groupName: this.selectedGroupName,
          isUser: false,
        };
        this.office365ResourceStore
          .addOffice365ResourceInfo(office365ResourceBody)
          .then((resp) => {
            this.processOrganizationUsers().then((resp) => {});
            this.selectedGroupName = null;
            this.selectedOption = null;
          });
      } catch (error) {
        console.error("Failed to fetch group members:", error);
      }
    },
    confirmToggle() {
      if (!this.clientData) {
        this.isSyncOffice365UsersPopupVisible = true;
        return;
      }

      const newValue = !this.isSyncedChecked;
      this.$confirm.require({
        message: "Do you want to toggle the webhooks?",
        header: `Turn webhooks ${newValue ? "on" : "off"}`,
        accept: () => {
          this.isSyncedChecked = newValue;
          const office365WebhookToggleBody = {
            businessId: this.businessId,
            isWebhook: this.isSyncedChecked,
          };

          this.office365WebhookToggleStore
            .addoffice365WebhookToggleInfo(office365WebhookToggleBody)
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Updated",
                detail: "Webhook is toggled successfully.",
                life: 5000,
              });
            });
        },
        reject: () => {
          // Do nothing; the checkbox remains in its current state.
        },
      });
    },
    async handleLogout() {
      this.$confirm.require({
        message: "Text Placeholder",
        header: "Are you sure?",
        accept: async () => {
          this.signOut();
          this.azureAdStore.deleteAzureADUser(this.businessId).then(() => {
            this.clientDataStore.deleteClientData(this.businessId).then(() => {
              this.office365WebhookToggleStore.deleteOffice365WebhookToggleInfo(
                this.businessId
              );
              this.$toast.add({
                severity: "success",
                summary: "Updated",
                detail: `AzureAdUser is deleted successfully.`,
                life: 2000,
              });
              this.authenticationStatus = "Unauthorized";

              setTimeout(() => {
                location.reload();
              }, 3000);
            });
          });
        },
        reject: () => {
          return;
        },
      });
    },
    onDropdownSelect(val) {
      this.selectedGroupName = val.value;
    },
    async handleInputs() {
      if (this.selectedOption == "all-users") {
        this.closePopup();
        this.getOrganizationUsers(this.azureAdAdminData.accessToken).then(
          (result) => {
            this.organizationUsers = result;
            const office365ResourceBody = {
              businessId: this.businessId,
              isUser: true,
              isGroup: false,
              groupName: null,
            };
            this.office365ResourceStore
              .addOffice365ResourceInfo(office365ResourceBody)
              .then((resp) => {
                this.processOrganizationUsers();
              });
          }
        );
      } else if (this.selectedOption == "group") {
        this.closePopup();
        this.fetchGroupMembers();
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Warning",
          detail: `Please select the option`,
          life: 5000,
        });
      }
    },
    handleSubtitle() {
      if (this.azureAdAdminData) {
        return this.azureAdAdminData.userName;
      } else "--";
    },
    async handleClientData() {
      if (!this.clientId || !this.tenantId) {
        this.clientDataError = "Client Id and Tenant Id is Required";
        return;
      }

      const clientDataBody = {
        businessId: this.accountStore.user.belongToBusinessObjId,
        tenantId: this.tenantId,
        clientId: this.clientId,
      };

      try {
        const resp = await this.clientDataStore.addClientData(clientDataBody);
        if (resp) {
          this.$toast.add({
            severity: "success",
            summary: "Added",
            detail: `Client Data added successfully.`,
            life: 3000,
          });
          this.clientData = resp;
          console.log(resp);

          this.clientId = resp.clientId || null;
          this.tenantId = resp.tenantId || null;

          if (this.clientId && this.tenantId) {
            const msalConfig = {
              auth: {
                clientId: this.clientId,
                authority: `https://login.microsoftonline.com/${this.tenantId}`,
                redirectUri: this.redirectUri,
              },
            };
            this.msalInstance = new PublicClientApplication(msalConfig);
            await this.msalInstance.initialize(); // Ensure MSAL instance is initialized
            this.msalInitialized = true;

            this.azureAdAdminData = await this.azureAdStore.getAzureADUser(
              this.businessId
            );
            if (this.azureAdAdminData.isAuthorize == true) {
              this.isAuthorizedChecked = true;
            }
            if (this.azureAdAdminData && this.azureAdAdminData.isAuthorize) {
              this.isAuthorizedChecked = true;
              this.authenticationStatus = "Authorized";
              const groupname = await this.fetchGroups(
                this.azureAdAdminData.accessToken
              );

              this.dropdownOptions = groupname.map((group) => ({
                name: group.displayName,
                code: group.displayName,
              }));
            }
          }
        }
      } catch (error) {
        console.error("Error adding client data:", error);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: `Failed to add client data. ${error.message}`,
          life: 3000,
        });
      }
    },
    async updateClientData() {
      if (!this.clientId || !this.tenantId) {
        this.clientDataError = "Client Id and Tenant Id is Required";
        return;
      }
      const updatedClientDataBody = {
        tenantId: this.tenantId,
        clientId: this.clientId,
      };

      this.clientDataStore
        .updateClientData(this.businessId, updatedClientDataBody)
        .then((resp) => {
          if (resp) {
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: `Client Data updated successfully.`,
              life: 3000,
            });
            setTimeout(() => {
              location.reload();
            }, 2000);
          }
        });
    },
  },
};
</script>

<template>
  <PVPopup
    v-model:visible="isSyncOffice365UsersPopupVisible"
    modal
    header="Microsoft 365"
    dismissableMask
    :draggable="false"
    :style="{ width: '80vw', maxWidth: '500px' }"
    class="add-card-modal"
    @hide="this.closePopup"
  >
    <div class="modal-content">
      <ArticleListContainer
        title="Client Data"
        avatar="url"
        v-if="!this.clientData"
        class="mb-2"
      >
        <InputWrapper
          labelText="Client Id"
          variant="grey"
          labelRef="clientId"
          v-model="this.clientId"
          :class="{ 'input-error': clientDataError }"
          :isRequired="true"
        />

        <InputWrapper
          labelText="Tenant Id"
          labelRef="tenantId"
          variant="grey"
          v-model="this.tenantId"
          :class="{ 'input-error': clientDataError }"
          :isRequired="true"
        />

        <div class="btn-fullwidth modal-buttons">
          <PVButton class="btn btn-primary" label="Save" type="submit"
            @keydown.enter="this.handleClientData" @click="this.handleClientData" autofocus />
        </div>
        <div v-if="this.clientData" class="btn-container btn-fullwidth">
          <PVButton
            class="btn btn-primary"
            label="Update"
            type="submit"
            @click="this.updateClientData"
            autofocus
          />
        </div>
      </ArticleListContainer>

      <ArticleListContainer :has-styling="false" avatar="url">
        <ArticleListItem
          v-if="!azureAdAdminData"
          title="Connect your account"
          subtitle="Login as Global Administrator"
          :isAvatar="false"
          imageUrl="https://connectflow.github.io/assets/app/static/microsoft-365.svg"
          bg="#f7f7f7"
          @click="handleAuthorization"
          :class="{ 'disabled-item': !this.clientData }"
          :style="{ opacity: !this.clientData ? '0.5' : '1' }"
        >
        </ArticleListItem>
        <ArticleListActionItem v-else title="Account connected" :subtitle="handleSubtitle() + ''"
          :isAvatar="false" imageUrl="https://connectflow.github.io/assets/app/static/microsoft-365.svg" bg="#f7f7f7">
          <div class="buttons">
            <PVButton
              class="btn-no-style"
              icon="pi pi-sign-out"
              title="Sign out"
              @click="handleLogout"
            />
          </div>
        </ArticleListActionItem>

        <!-- TODO: Check if this works - condition-->
        <PVButton
          v-if="this.office365ResourceData"
          class="btn-secondary btn delete-btn"
          @click="deleteOfffice365Users"
        >
          <i class="pi pi-trash"> </i>
          Delete Users
        </PVButton>

        <p v-if="!this.clientData" class="authentication-text">
          Check out our manual for
          <a href="https://ctfw.io/usersync-ms-manual" target="_blank"
            >Microsoft 365</a
          >
        </p>
      </ArticleListContainer>

      <div v-if="this.azureAdAdminData">
        <div class="user-selection">
          <div class="radio-box">
            <input
              type="radio"
              id="all-users"
              name="userSelection"
              v-model="selectedOption"
              value="all-users"
            />
            <label for="all-users">
              <IconUsers class="group-icon" />
              All Users
            </label>
          </div>
          <div class="radio-box">
            <input
              type="radio"
              id="group"
              name="userSelection"
              v-model="selectedOption"
              value="group"
            />
            <label for="group">
              <IconGroup class="group-icon" />
              Group
            </label>
          </div>
        </div>
        <div class="dropdown" v-if="selectedOption === 'group'">
          <PVDropdown
            id="dropDown"
            optionLabel="name"
            optionValue="code"
            v-model="userRadioSelection"
            :options="dropdownOptions"
            placeholder="Select group"
            @change="onDropdownSelect"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="btn-container" v-if="this.azureAdAdminData">
        <PVButton
          class="btn btn-secondary"
          label="Cancel"
          @click="this.closePopup"
          text
        />
        <PVButton
          class="btn btn-primary"
          label="Save"
          type="submit"
          @click="this.handleInputs"
          autofocus
        />
      </div>
    </template>
  </PVPopup>

  <div class="container">
    <main>
      <ArticleListContainer title="Microsoft 365" avatar="url">
        <ArticleListActionItem
          class="sync-icon"
          title="Microsoft 365"
          :subtitle="this.authorizationStatus"
          :isAvatar="false"
          imageUrl="https://connectflow.github.io/assets/app/static/microsoft-365.svg"
          icon="pi pi-unlock"
          v-model="this.authoziationPopupCheck"
          @click="openPopup"
        >
          <div class="switch" @click.stop>
            <label class="toggle-switch">
              <input
                type="checkbox"
                :checked="this.isSyncedChecked"
                @click.prevent="confirmToggle"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </ArticleListActionItem>
      </ArticleListContainer>
    </main>
  </div>
</template>

<style lang="scss" scoped>
main {
  margin-top: 75px;
  margin-bottom: 50px;
  width: 90vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  animation: fade-in 0.8s cubic-bezier(0, 0, 0, 1) forwards;
  animation: move-up-50 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}

.ArticleListInput {
  width: 100%;
  padding-left: 15px;
  padding-right: 20px;
  padding-top: calc(15px - 5px);
  padding-bottom: calc(15px - 10px);
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
  /* Adjusted border-radius */
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  /* Adjusted height */
  width: 16px;
  /* Adjusted width */
  left: 2px;
  /* Adjusted positioning */
  bottom: 2px;
  /* Adjusted positioning */
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #222;
}

input:checked + .slider:before {
  transform: translateX(16px);
  /* Adjusted translate value */
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
  /* Adjusted border-radius */
}

.slider.round:before {
  border-radius: 50%;
}

.sync-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.user-selection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin-top: 1rem;
}

.radio-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f7f7f7;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: border 0.3s ease;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  border: 1px solid black;
}

label {
  width: 100%;
  text-align: center;
  padding: 10px;
  font-weight: 600;
  font-size: 0.9em;
  gap: 10px;
  cursor: pointer;
  display: inline-block;
}

.radio-box input[type="radio"]:checked + label {
  border: 2px solid black;
  border-radius: 5px;
}

.group-icon {
  height: 10px;
}

.dropdown {
  margin-top: 1rem;
}

.p-dropdown {
  max-width: 100% !important;
  width: 100% !important;
}

.switch {
  display: flex;
}

.btnFlex {
  display: flex;
  /* Enable flexbox */
  justify-content: flex-end;
}

.modal-buttons {
  display: flex;
  gap: 8px;
}

.article__description {
  a {
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}

.authentication-text {
  margin-top: 20px;
  color: #222;

  a {
    color: #222;
    font-weight: bold;
  }
}

.buttons {
  display: flex;
  gap: 8px;
}

.delete-btn {
  margin-top: 16px;
  font-size: 1em;
  font-weight: 500;

  i {
    transform: scale(0.85);
  }
}

.p-dialog-footer {
  padding: 0px;
}

.container {
  padding: 0px !important;
}
.disabled-item {
  pointer-events: none;
  cursor: not-allowed;
}
</style>
