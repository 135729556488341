<template>
  <svg
    width="1154"
    height="1154"
    viewBox="0 0 1154 1154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="577" cy="577" r="577" fill="#E6E6E6" />
    <path
      d="M650.843 250.625L814.437 414.219L732.64 429.091L650.843 414.219L635.971 332.422L650.843 250.625Z"
      fill="#188038"
    />
    <path
      d="M650.862 414.219V250.625H383.163C358.513 250.625 338.547 270.591 338.547 295.241V860.384C338.547 885.034 358.513 905 383.163 905H769.839C794.49 905 814.456 885.034 814.456 860.384V414.219H650.862Z"
      fill="#34A853"
    />
    <path
      d="M427.783 503.45V719.096H725.226V503.45H427.783ZM557.915 681.916H464.964V629.863H557.915V681.916ZM557.915 592.683H464.964V540.63H557.915V592.683ZM688.046 681.916H595.095V629.863H688.046V681.916ZM688.046 592.683H595.095V540.63H688.046V592.683Z"
      fill="white"
    />
  </svg>
</template>
