import { defineStore } from "pinia";
import { BASE_URL, fetchWrapper } from "@/helpers";
import { PublicClientApplication } from '@azure/msal-browser';
import { useAccountStore, useBusinessUsersStore, } from "@/stores";
import { useAzureAdStore } from "@/stores/sso/azureAd.store";
import { useOffice365ResourceStore } from "@/stores/sso/office365Resource.store";
import type { WebhookState } from "./types";
import { useClientDataStore } from "@/stores";




export const useoffice365WebhookStore = defineStore({
    id: "office365Webhook",

    state: (): WebhookState => ({
        businessId: null,
        accountStore: useAccountStore(),
        azureAdStore: useAzureAdStore(),
        businessUsersStore: useBusinessUsersStore(),
        office365ResourceStore: useOffice365ResourceStore(),
        clientDataStore: useClientDataStore(),
        azureAdAdminData: null,
        office365ResourceData: null,
        msalInstance: null,
        clientId: null,
        tenantId: null
    }),


    actions: {
        async processWebhookNotification(notificationData) {
            // Create a new FormData object
            try {
                const response = await fetch(
                    `${BASE_URL}/office365Webhook/processWebhookNotification`,
                    {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            // Add any required headers here
                        },
                        body: JSON.stringify(notificationData),
                    }
                );
                if (response.ok) {
                    return await response.json();
                } else {
                    const errorData = await response.json();
                    throw new Error(`Error syncing webhook data: ${JSON.stringify(errorData)}`);
                }
            } catch (error) {
                console.error("Error syncing webhook data:", error);
                throw error;
            }
        },

        async fetchSubscriptions(accessToken) {
            const url = "https://graph.microsoft.com/v1.0/subscriptions";

            const fetchWithToken = async (token) => {
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                });

                if (response.status === 401) {
                    // Token expired, refresh it
                    const newTokens = await this.refreshAccessToken();
                    return fetchWithToken(newTokens.accessToken);
                } else if (response.ok) {
                    const data = await response.json();
                    return data.value;
                } else {
                    console.error("Error fetching subscriptions:", response.statusText);
                    throw new Error("Failed to fetch subscriptions");
                }
            };

            return fetchWithToken(accessToken);
        },

        async deleteAllSubscriptions(accessToken) {
            try {
                const subscriptions = await this.fetchSubscriptions(accessToken);

                if (subscriptions.length > 0) {
                    for (const subscription of subscriptions) {
                        const url = `https://graph.microsoft.com/v1.0/subscriptions/${subscription.id}`;

                        const deleteWithToken = async (token) => {
                            const response = await fetch(url, {
                                method: "DELETE",
                                headers: {
                                    "Authorization": `Bearer ${token}`,
                                    "Content-Type": "application/json"
                                }
                            });

                            if (response.status === 401) {
                                // Token expired, refresh it
                                const newTokens = await this.refreshAccessToken();
                                return deleteWithToken(newTokens.accessToken);
                            } else if (response.ok) {
                                console.log(`Subscription with ID ${subscription.id} deleted successfully.`);
                            } else {
                                console.error(`Error deleting subscription with ID ${subscription.id}:`, response.statusText);
                            }
                        };

                        await deleteWithToken(accessToken);
                    }
                } else {
                    console.log("No subscriptions found to delete.");
                }
            } catch (error) {
                console.error("Error deleting subscriptions:", error);
            }
        },

        async deleteSubscription(subscriptionId, accessToken) {
            const url = `https://graph.microsoft.com/v1.0/subscriptions/${subscriptionId}`;

            const deleteWithToken = async (token) => {
                const response = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 401) {
                    // Token expired, refresh it
                    const newTokens = await this.refreshAccessToken();
                    return deleteWithToken(newTokens.accessToken);
                } else if (response.ok) {
                    console.log(`Subscription with ID ${subscriptionId} deleted successfully.`);
                    return true;
                } else {
                    const errorData = await response.json();
                    console.error('Failed to delete subscription', errorData);
                    throw new Error(`Failed to delete subscription with ID ${subscriptionId}`);
                }
            };

            try {
                return await deleteWithToken(accessToken);
            } catch (error) {
                console.error(`Error deleting subscription with ID ${subscriptionId}:`, error);
                return false;
            }
        },

        async refreshAccessToken() {
            const accounts = this.msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                throw new Error("No accounts found.");
            }

            const silentRequest = {
                account: accounts[0],
                scopes: ['User.Read', 'Group.Read.All'],
            };

            try {
                const response = await this.msalInstance.acquireTokenSilent(silentRequest);
                return {
                    accessToken: response.accessToken,
                };
            } catch (error) {
                console.error('Failed to refresh access token:', error);
                throw error;
            }
        },

        async checkSubscriptionExists(resource, accessToken) {
            const subscriptionsEndpoint = "https://graph.microsoft.com/v1.0/subscriptions";
            const headers = new Headers();
            headers.append("Authorization", `Bearer ${accessToken}`);

            const options = {
                method: "GET",
                headers: headers,
            };

            try {
                let response = await fetch(subscriptionsEndpoint, options);
                if (response.status === 401) {
                    const newTokens = await this.refreshAccessToken();
                    headers.set("Authorization", `Bearer ${newTokens.accessToken}`);
                    response = await fetch(subscriptionsEndpoint, options);
                }
                const subscriptions = await response.json();

                if (subscriptions.value) {
                    const existingSubscriptions = subscriptions.value.filter(sub => sub.resource === resource);
                    const otherResourceSubscriptions = subscriptions.value.filter(sub => sub.resource !== resource);

                    for (let sub of otherResourceSubscriptions) {
                        await this.deleteSubscription(sub.id, accessToken);
                    }
                    return existingSubscriptions.length > 0 ? existingSubscriptions : false;
                }

            } catch (error) {
                console.error("Failed to check existing subscriptions:", error);
                return false;
            }
        },

        async createSubscription(resource, accessToken) {
            const subscriptionEndpoint = "https://graph.microsoft.com/v1.0/subscriptions";
            const headers = new Headers();
            headers.append("Authorization", `Bearer ${accessToken}`);
            headers.append("Content-Type", "application/json");

            const subscription = {
                changeType: "created,updated,deleted",
                notificationUrl: `${BASE_URL}/office365Webhook/createWebHookSubcription`,
                // notificationUrl: `https://90b3-2409-4081-dc7-42f7-c096-9e7b-48ce-4b9d.ngrok-free.app/api/v1/office365Webhook/createWebHookSubcription`,
                resource: resource, // e.g., "users" or "groups"
                expirationDateTime: new Date(Date.now() + 3600 * 1000 * 24).toISOString(), // 24-hour expiration
                clientState: "secretClientValue"
            };

            const options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(subscription)
            };

            try {
                const response = await fetch(subscriptionEndpoint, options);
                const responseBody = await response.json();


                if (!response.ok) {
                    if (response.status === 401) {
                        const newTokens = await this.refreshAccessToken();

                        headers.set("Authorization", `Bearer ${newTokens.accessToken}`);
                        const retryResponse = await fetch(subscriptionEndpoint, options);
                        const retryResponseBody = await retryResponse.json();

                        if (!retryResponse.ok) {
                            throw new Error("Failed to create subscription after token refresh");
                        }
                    } else {
                        throw new Error(`Failed to create subscription: ${responseBody.error.message}`);
                    }
                } else {
                    console.log("Subscription created successfully:", responseBody);
                }
            } catch (error) {
                console.error("Error creating subscription:", error);
            }
        },

        async processWebhookSync() {
            const isDevelopment = import.meta.env.VITE_USER_NODE_ENV === "DEVELOPMENT";
            const isProduction = import.meta.env.VITE_USER_NODE_ENV === "PRODUCTION";
            let redirectionUrl;
            if (isProduction) {
                redirectionUrl = "https://connectflow.app"
            }
            else if (isDevelopment) {
                redirectionUrl = "https://frontend-test.connectflow.app";
            } else {
                redirectionUrl = "http://localhost:5173";
            }
            console.log("edirectionUrl",redirectionUrl)
            this.businessId = localStorage.getItem("businessID")
            const isLogin = localStorage.getItem("isLogin")
            const result = await this.clientDataStore.getClientData(this.businessId);
            this.clientId = result ? result.clientId : null;
            this.tenantId = result ? result.tenantId : null;
            const msalConfig = {
                auth: {
                    clientId: `${this.clientId}`, // Replace with your client ID
                    authority: `https://login.microsoftonline.com/${this.tenantId}`, // Replace with your tenant ID
                    redirectUri: redirectionUrl,
                    // redirectUri: 'https://frontend-test.connectflow.app/'
                },
            };

            // Initialize msalInstance
            // const msalConfig = {
            //     auth: {
            //         clientId: '951ba740-857d-42e6-a4b6-9513d6dda558', // Replace with your client ID
            //         authority: 'https://login.microsoftonline.com/1ff3b07c-9769-4b50-81af-3e96622b861e', // Replace with your tenant ID
            //         redirectUri: 'https://frontend-test.connectflow.app/'
            //     }
            // };
            this.msalInstance = new PublicClientApplication(msalConfig);
            await this.msalInstance.initialize(); // Ensure MSAL instance is initialized
            this.msalInitialized = true;

            if (isLogin) {
                this.azureAdAdminData = await this.azureAdStore.getAzureADUser(this.businessId);
                this.office365ResourceData = await this.office365ResourceStore.getOffice365ResourceInfo(this.businessId);
                if (this.azureAdAdminData && this.azureAdAdminData.isAuthorize) {
                    try {
                        if (this.office365ResourceData.isUser) {
                            const userSubscriptionExists = await this.checkSubscriptionExists("users", this.azureAdAdminData.accessToken);
                            if (!userSubscriptionExists) {
                                await this.createSubscription("users", this.azureAdAdminData.accessToken);
                                const currentDateString = new Date().toISOString();

                                let newTokens = null;
                                if (this.azureAdAdminData.expiresOn < currentDateString) {
                                    const userSubscriptionExists = await this.checkSubscriptionExists("users", this.azureAdAdminData.accessToken);
                                    await this.createSubscription("users", this.azureAdAdminData.accessToken);

                                    newTokens = await this.refreshAccessToken();
                                    const webhookData = {
                                        data: userSubscriptionExists,
                                        accessToken: newTokens.accessToken,
                                        businessId: this.businessId

                                    }
                                    const result = await this.processWebhookNotification(webhookData);
                                    console.log("Data updated", result);

                                } else {
                                    const userSubscription = await this.checkSubscriptionExists("users", this.azureAdAdminData.accessToken);
                                    const webhookData = {
                                        data: userSubscription,
                                        accessToken: this.azureAdAdminData.accessToken,
                                        businessId: this.businessId
                                    }
                                    const result = await this.processWebhookNotification(webhookData);
                                    console.log("Data updated", result);


                                }
                            } else {
                                // Define the threshold for expiration (e.g., 24 hours before expiration)
                                const expirationThreshold = 60 * 60 * 1000; // 24 hours in milliseconds
                                const currentDate = new Date();
                                const subscriptionExpirationDate = new Date(userSubscriptionExists[0].expirationDateTime);
                                const timeUntilExpiration = subscriptionExpirationDate - currentDate;

                                if (timeUntilExpiration < expirationThreshold) {
                                    console.log("Subscription is about to expire, renewing it");

                                    // Refresh the access token if necessary
                                    let accessToken = this.azureAdAdminData.accessToken;
                                    if (new Date(this.azureAdAdminData.expiresOn) < currentDate) {
                                        const newTokens = await this.refreshAccessToken();
                                        accessToken = newTokens.accessToken;
                                        console.log("newTokens", newTokens.accessToken);
                                    }

                                    // Delete the old subscription
                                    await this.deleteSubscription(userSubscriptionExists.id, accessToken);
                                    // Create a new subscription
                                    await this.createSubscription("users", accessToken);



                                    // Get the new subscription details
                                    const newUserSubscription = await this.checkSubscriptionExists("users", accessToken);
                                    const webhookData = {
                                        data: newUserSubscription.value,
                                        accessToken: accessToken,
                                        businessId: this.businessId
                                    };
                                    const result = await this.processWebhookNotification(webhookData);
                                    console.log("Data updated", result);

                                }
                                else {

                                    const currentDateString = new Date().toISOString();
                                    let newTokens;
                                    if (this.azureAdAdminData.expiresOn < currentDateString) {

                                        newTokens = await this.refreshAccessToken();
                                        const webhookData = {
                                            data: userSubscriptionExists,
                                            accessToken: newTokens.accessToken,
                                            businessId: this.businessId
                                        }
                                        const result = await this.processWebhookNotification(webhookData);
                                        console.log("Data updated", result);

                                    } else {
                                        const webhookData = {
                                            data: userSubscriptionExists,
                                            accessToken: this.azureAdAdminData.accessToken,
                                            businessId: this.businessId
                                        }
                                        const result = await this.processWebhookNotification(webhookData);
                                        console.log("Data updated", result);

                                    }
                                }
                            }

                        }
                        else if (this.office365ResourceData.isGroup) {


                            const groupSubscriptionExists = await this.checkSubscriptionExists("groups", this.azureAdAdminData.accessToken);

                            if (!groupSubscriptionExists) {
                                const currentDateString = new Date().toISOString();

                                let newTokens = null;
                                if (this.azureAdAdminData.expiresOn < currentDateString) {
                                    await this.createSubscription("groups", this.azureAdAdminData.accessToken);
                                    const groupSubscriptionExists = await this.checkSubscriptionExists("groups", this.azureAdAdminData.accessToken);

                                    newTokens = await this.refreshAccessToken();

                                    const webhookData = {
                                        data: groupSubscriptionExists,
                                        accessToken: newTokens.accessToken,
                                        businessId: this.businessId

                                    }
                                    const result = await this.processWebhookNotification(webhookData);
                                    console.log("Data updated", result);

                                } else {
                                    await this.createSubscription("groups", this.azureAdAdminData.accessToken);
                                    const groupSubscriptionExists = await this.checkSubscriptionExists("groups", this.azureAdAdminData.accessToken);

                                    const webhookData = {
                                        data: groupSubscriptionExists,
                                        accessToken: this.azureAdAdminData.accessToken,
                                        businessId: this.businessId
                                    }
                                    const result = await this.processWebhookNotification(webhookData);
                                    console.log("Data updated", result);

                                }

                            } else {

                                const expirationThreshold = 60 * 60 * 1000; // 24 hours in milliseconds
                                const currentDate = new Date();
                                const subscriptionExpirationDate = new Date(groupSubscriptionExists[0].expirationDateTime);


                                const timeUntilExpiration = subscriptionExpirationDate - currentDate;

                                if (timeUntilExpiration < expirationThreshold) {
                                    console.log("Subscription is about to expire, renewing it");

                                    // Refresh the access token if necessary
                                    let accessToken = this.azureAdAdminData.accessToken;
                                    if (new Date(this.azureAdAdminData.expiresOn) < currentDate) {
                                        const newTokens = await this.refreshAccessToken();
                                        accessToken = newTokens.accessToken;
                                    }

                                    // Delete the old subscription
                                    await this.deleteSubscription(groupSubscriptionExists.id, accessToken);

                                    // Create a new subscription
                                    await this.createSubscription("groups", accessToken);

                                    // Get the new subscription details
                                    const newGroupSubscription = await this.checkSubscriptionExists("groups", accessToken);
                                    const webhookData = {
                                        data: newGroupSubscription,
                                        accessToken: accessToken,
                                        businessId: this.businessId
                                    };
                                    const result = await this.processWebhookNotification(webhookData);
                                    console.log("Data updated", result);

                                }
                                else {

                                    const currentDateString = new Date().toISOString();

                                    let newTokens;
                                    if (this.azureAdAdminData.expiresOn < currentDateString) {
                                        newTokens = await this.refreshAccessToken();
                                        const webhookData = {
                                            data: groupSubscriptionExists,
                                            accessToken: newTokens.accessToken,
                                            businessId: this.businessId

                                        }
                                        const result = await this.processWebhookNotification(webhookData);
                                        console.log("Data updated", result);

                                    } else {
                                        const webhookData = {
                                            data: groupSubscriptionExists,
                                            accessToken: this.azureAdAdminData.accessToken,
                                            businessId: this.businessId
                                        }
                                        const result = await this.processWebhookNotification(webhookData);
                                        console.log("Data updated", result);

                                    }
                                }
                            }
                        }
                    }

                    catch (error) {
                        console.error("Error in createSubscription:", error);
                    }

                }
                else {
                    console.log("no user found")
                    return;
                }
            }
            else {
                console.log("user is not login")
                return;
            }
        },
    },
});