import { defineStore } from "pinia";
import { BASE_URL,fetchWrapper } from "@/helpers";

export const useClientDataStore = defineStore({
  id: "clientData",
  
  actions: {
    async addClientData(clientDataBody) {
      // Create a new FormData object
      try {
        const response = await fetch(
          `${BASE_URL}/clientDataRoute/addClientData`,
          {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                // Add any required headers here
              },
              body: JSON.stringify(clientDataBody),  }
        );

        if (response.ok) {
           return await response.json();
        } else {
          const errorData = await response.json();
          throw new Error(`Error adding card type: ${JSON.stringify(errorData)}`);
        }
      } catch (error) {
        console.error("Error adding card type:", error);
        throw error;
      }
    },

    async getClientData(businessId) {
      try {
        const response = await fetchWrapper.get(`${BASE_URL}/clientDataRoute/getClientDataByBusinessId/${businessId}`);
        return response; // Or process the response here
      } catch (error) {
        console.error("Error fetching client data:", error);
        throw error;
      }
    },
    async updateClientData(businessId: string, data) {
      return await fetchWrapper.patch(
        `${BASE_URL}/clientDataRoute/updateClientDataByBusinessId/${businessId}`,
        data
      );
    },
    async deleteClientData(businessId: string) {
      await fetchWrapper.delete(`${BASE_URL}/clientDataRoute/deleteClientDataByBusinessId/${businessId}`);
    },
  },
});