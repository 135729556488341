import { defineStore } from "pinia";

import { fetchWrapper, BASE_URL } from "@/helpers";

export const useBusinessGoogleUrlStore = defineStore({
    id: "business-googleUrl",
    actions: {

        async getBusinessGoogleUrl(
            businessId: string,
        ) {
            const response = await fetchWrapper.get(`${BASE_URL}/googleUrl/getGoogleUrl/${businessId}`);

            if (response) {
                // Successfully uploaded
                const responseData = await response; // Parse the response body as JSON
                return responseData; // Return the parsed response data
            }
            else if (response == null) {
                return response;
            }
        },

    },
});