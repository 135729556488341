<script lang="ts">
import ArticleListContainer from "@/components/List/ListContainer/ArticleListContainer.vue";
import ArticleListActionItem from "@/components/List/ListItem/ArticleListActionItem.vue";

import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";
import InputWrapper from "@/components/Input/InputWrapper.vue";

import { formatLocationString, isEmpty } from "@/helpers";

import { useBusinessConnectionStore, useBusinessUsersStore } from "@/stores";

export default {
  data() {
    return {
      connectId: "",
      businessConnectionStore: useBusinessConnectionStore(),
      businessUsersStore: useBusinessUsersStore(),
      connection: {},
      user: {},
      formattedAddressString: "",
      showSpinner: false,
    };
  },
  components: {
    ArticleListContainer,
    ArticleListActionItem,
    IconArrowLeft,
    InputWrapper,
  },
  async created() {
    const rawConnectId = this.$route.params.connectionId;

    if (Array.isArray(rawConnectId)) {
      console.error("Unexpected array for connectId:", rawConnectId);
      return;
    }

    this.connectId = rawConnectId.toString();

    this.connection = await this.getConnection(this.connectId);

    if (this.connection.locationConnected) {
      this.formatCoordinates(this.connection.locationConnected);
    }

    const userId = this.connection.userId;

    if (userId) {
      const user = await this.businessUsersStore.getBusinessUser(userId);
      this.user = user;
    }
    // Adjust the textarea height based on initial content
    this.adjustTextareaHeight(this.connection.note);
  },
  computed: {
    connectedAt() {
      if (!this.connection.createdAt) return null;

      const date = new Date(this.connection.createdAt);
      return {
        year: date.getFullYear(),
        month: String(date.getMonth() + 1).padStart(2, "0"), // months are 0-indexed
        day: String(date.getDate()).padStart(2, "0"),
      };
    },
    connectedAtLink() {
      if (!this.connectedAt) return "";

      return `https://calendar.google.com/calendar/u/0/r/day/${this.connectedAt.year}/${this.connectedAt.month}/${this.connectedAt.day}`;
    },
    isConnectionEmpty() {
      return isEmpty(this.connection);
    },
  },
  methods: {
    async getConnection(connectId: string) {
      return await this.businessConnectionStore.getBusinessConnection(
        connectId
      );
    },
    adjustTextareaHeight(initialContent: string) {
      const textarea = this.$refs.textarea;
      textarea.value = initialContent; // Set the initial content
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    formatDate(inputDate: string) {
      const dateObj = new Date(inputDate);

      // Function to get the month name based on the month number
      function getMonthName(month) {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return months[month];
      }

      const monthName = getMonthName(dateObj.getMonth());
      const day = dateObj.getDate();
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();

      const formattedHours =
        hours.toString().length === 1 ? `0${hours}` : hours;
      const formattedMinutes =
        minutes.toString().length === 1 ? `0${minutes}` : minutes;

      // Format the date and time in the desired format
      const formattedDate = `${monthName} ${day} ${year} ${formattedHours}:${formattedMinutes}`;
      return formattedDate;
    },
    getConnectedWithFullName() {
      return `${this.user.fname} ${this.user.lname}`;
    },
    getConnectedWithLink() {
      return `/business/users/user/${this.user.userID}`;
    },
    async formatCoordinates(coordsString: string) {
      const [lat, lon] = coordsString.split("|");

      const geoUrl = "https://geocode.maps.co/reverse?";
      const geoQuery = `lat=${lat}&lon=${lon}`;
      const apiKey = "660c5cdc61f7a109830061tpxfe3d08"; // 1 request per second
      const finalUrl = geoUrl + geoQuery + `&api_key=${apiKey}`;

      try {
        const response = await fetch(finalUrl);
        const data = await response.json();

        this.formattedAddressString = await formatLocationString(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle any errors that occurred during the fetch or parsing
        return "Location can't be found";
      }
    },
    getGoogleMapsLink(coordsString: string) {
      const [lat, lon] = coordsString.split("|");
      return `https://maps.google.com?q=${lat},${lon}`;
    },
    async handleNote(event: Event) {
      const input = event.target as HTMLInputElement;
      const labelName = input.previousSibling?.innerHTML;
      try {
        const value = input.value;
        const updatedConnection = { ...this.connection };
        updatedConnection.note = value;
        await this.businessConnectionStore
          .updateBusinessUserConnections(this.connectId, updatedConnection)
          .then((res) => {
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: `Note is updated.`,
              life: 5000,
            });
          });
      } catch (error) {
        console.error("Error updating setting:", error);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: `Failed to update ${labelName}. Error: ${error.message}`,
          life: 5000,
        });
      }
    },
    async deleteConnection(connectId: string) {
      this.$confirm.require({
        message: "Are you sure you want to remove this connection?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          this.showSpinner = true;
          await this.businessConnectionStore
            .deleteBusinessConnection(connectId)
            .then(() => {
              this.showSpinner = false;
              this.$router.push("/business/connections");
              this.$toast.add({
                severity: "success",
                summary: "Removed",
                detail: `Connection is removed`,
                life: 3000,
              });
            })
            .catch((error) => {
              this.showSpinner = false;
              this.$toast.add({
                severity: "error",
                summary: "Failed",
                detail: `Something went wrong: ${error}`,
                life: 3000,
              });
            });
        },
        reject: () => {
          this.$toast.add({
            severity: "error",
            summary: "Cancelled",
            detail: "Connection is not removed",
            life: 3000,
          });
        },
      });
    },
    increaseSize() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    copyToClipboard(content: string) {
      navigator.clipboard.writeText(content);
      this.$toast.add({
        severity: "success",
        summary: "Copied",
        detail: `${content} is copied to your clipboard.`,
        life: 5000,
      });
    },
    copyAllToClipboard() {
      const fullName = `${this.connection.fname} ${this.connection.lname}`;
      const companyName = this.connection.company;
      const emailAddress = this.connection.email;
      const phoneNumber = this.connection.phone;

      const fullString =
        fullName +
        "\n" +
        companyName +
        "\n" +
        emailAddress +
        "\n" +
        phoneNumber;

      this.copyToClipboard(fullString);
    },
    saveVcard() {
      const fname = this.connection.fname ?? "Unknown";
      const lname = this.connection.lname ?? "Unknown";
      const company = this.connection.company ?? "";
      const phone = this.connection.phone ?? "";
      const email = this.connection.email ?? "";
      const connectId = this.connection.connectId;

      const data =
        "BEGIN:VCARD\nVERSION:3.0\nN;CHARSET=UTF-8:" +
        lname +
        ";" +
        fname +
        ";;;\nFN;CHARSET=UTF-8:" +
        fname +
        " " +
        lname +
        "\nORG;CHARSET=UTF-8:" +
        company +
        "\nTEL;TYPE=Phone:" +
        phone +
        "\nEMAIL;CHARSET=UTF-8;type=Email,INTERNET:" +
        email +
        "\nURL;TYPE=Connection:https://connectflow.app/account/connections/connection/" +
        connectId +
        "\nEND:VCARD";

      const url = "data:text/x-vcard;charset=utf-8," + encodeURIComponent(data);
      const link = document.createElement("a");
      link.download = fname + "_" + lname + "_" + connectId + ".vcf";
      link.href = url;

      //   Workaround to make sure the link is ready (Safari)
      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }, 0);
    },
  },
};
</script>

<template>
  <div v-if="showSpinner" class="spinner">
    <PVProgressSpinner />
  </div>
  <div class="container">
    <header class="card-header">
      <router-link :to="`/business/connections`">
        <IconArrowLeft />
      </router-link>
      <h1>Connection</h1>
      <div class=""></div>
    </header>

    <main v-if="!this.connection.fname">
      <PVSkeleton width="100%" height="340px" />
    </main>

    <main v-else>
      <ArticleListContainer title="About">
        <ArticleListActionItem
          :title="`${this.connection.fname} ${this.connection.lname}`"
          subtitle=""
          :isAvatar="false"
          icon="pi pi-user"
          @click="
            copyToClipboard(this.connection.fname + ' ' + this.connection.lname)
          "
        >
          <PVButton
            icon="pi pi-copy"
            aria-label="Copy to clipboard"
            class="btn btn-icon"
          />
        </ArticleListActionItem>
        <ArticleListActionItem
          v-if="this.connection.company"
          :title="this.connection.company"
          subtitle=""
          :isAvatar="false"
          icon="pi pi-building"
          @click="copyToClipboard(this.connection.company)"
        >
          <PVButton
            icon="pi pi-copy"
            aria-label="Copy to clipboard"
            class="btn btn-icon"
        /></ArticleListActionItem>
        <ArticleListActionItem
          v-if="this.connection.email"
          :title="this.connection.email"
          subtitle=""
          :isAvatar="false"
          icon="pi pi-envelope"
          @click="copyToClipboard(this.connection.email)"
        >
          <PVButton
            icon="pi pi-copy"
            aria-label="Copy to clipboard"
            class="btn btn-icon"
          />
        </ArticleListActionItem>
        <ArticleListActionItem
          v-if="this.connection.phone"
          :title="this.connection.phone"
          subtitle=""
          :isAvatar="false"
          icon="pi pi-phone"
          @click="copyToClipboard(this.connection.phone)"
        >
          <PVButton
            icon="pi pi-copy"
            aria-label="Copy to clipboard"
            class="btn btn-icon"
          />
        </ArticleListActionItem>
      </ArticleListContainer>
      <ArticleListContainer title="Information">
        <ArticleListActionItem
          v-if="this.user.fname"
          title="Connected with"
          :subtitle="getConnectedWithFullName()"
          :link="getConnectedWithLink()"
          :isAvatar="true"
          :imageUrl="user.image"
        />
        <ArticleListActionItem
          v-if="this.connection.createdAt"
          title="Date connected"
          :subtitle="formatDate(this.connection.createdAt)"
          :link="connectedAtLink"
          :newTabLink="true"
          :isAvatar="false"
          icon="pi pi-calendar"
        />

        <ArticleListActionItem
          v-if="this.connection.locationConnected"
          title="Location connected"
          :subtitle="this.formattedAddressString"
          :link="this.getGoogleMapsLink(this.connection.locationConnected)"
          :newTabLink="true"
          :isAvatar="false"
          icon="pi pi-map-marker"
        />
      </ArticleListContainer>
      <ArticleListContainer title="Note">
        <div class="input-wrapper input-inline-label">
          <textarea
            id="note"
            class="input-text p-inputtext"
            maxlength="200"
            placeholder="Add Note"
            v-model="this.connection.note"
            @input="increaseSize"
            ref="textarea"
            @change="handleNote"
          />
        </div>
      </ArticleListContainer>
      <ArticleListContainer title="Actions">
        <ArticleListActionItem
          title="Copy all"
          subtitle=""
          :isAvatar="false"
          icon="pi pi-clone"
          @click="copyAllToClipboard"
        >
          <PVButton
            icon="pi pi-copy"
            aria-label="Copy to clipboard"
            class="btn btn-icon"
          />
        </ArticleListActionItem>
        <ArticleListActionItem
          title="Add to contacts"
          :subtitle="`Save ${this.connection.fname} to your contacts`"
          link="#"
          :isAvatar="false"
          icon="pi pi-user"
          @click="saveVcard"
        >
          <PVButton
            icon="pi pi-download"
            aria-label="Add to contacts"
            class="btn btn-icon"
          />
        </ArticleListActionItem>
        <ArticleListActionItem
          @click="deleteConnection(this.connectId)"
          title="Delete connection"
          subtitle=""
          :isAvatar="false"
          icon="pi pi-times"
        >
          <PVButton
            icon="pi pi-trash"
            aria-label="Add to contacts"
            class="btn btn-icon"
          />
        </ArticleListActionItem>
      </ArticleListContainer>
    </main>
  </div>
</template>

<style lang="scss" scoped>
main,
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
main {
  padding: 0px 0px 20px 0px;
  width: 100%;
  max-width: 500px;

  & > * {
    margin-top: 52px;
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-inline-label label {
  color: var(--color-text-grey);
  font-weight: 600;
  font-size: 0.8em;
  position: absolute;
  left: 12px;
  top: 0;
  z-index: 2;
  padding-top: 0.25rem;
  text-transform: capitalize;
}

.input-text {
  width: 100%;
  color: var(--color-black);
  font-weight: 600;
  border: none;
  resize: none;
}

.container {
  padding: 0px 20px;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin-top: 50px;

  a {
    color: var(--color-black);
  }
}
</style>
