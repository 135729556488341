import { defineStore } from "pinia";
import { BASE_URL, fetchWrapper } from "@/helpers";

export const useOffice365WebhookToggleStore = defineStore({
  id: "office365WebhookToggleStore",

  actions: {
    async addoffice365WebhookToggleInfo(office365WebhookToggleBody) {

      // Create a new FormData object
      try {
        const response = await fetch(
          `${BASE_URL}/office365WebhookToggleRoute/addOffice365WebHookToggle`,
          {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              // Add any required headers here
            },
            body: JSON.stringify(office365WebhookToggleBody),
          }
        );

        if (response.ok) {
          return await response.json();
        } else {
          const errorData = await response.json();
          throw new Error(`Error Creating office365 Webhook Toggle Info: ${JSON.stringify(errorData)}`);
        }
      } catch (error) {
        console.error("Error Creating office365 Webhook Toggle Info:", error);
        throw error;
      }
    },

    async getOffice365WebhookToggleInfo(businessId: string) {
      return await fetchWrapper.get(`${BASE_URL}/office365WebhookToggleRoute/getOffice365WebHookToggle/${businessId}`);
    },

    async deleteOffice365WebhookToggleInfo(businessId: string) {
      await fetchWrapper.delete(`${BASE_URL}/office365WebhookToggleRoute/deleteOffice365WebHookToggle/${businessId}`);
    },
  },
});